import { createContext, useContext } from 'react'

/* Local Types */
interface CustomerContextValues {
	/* Locale Context */
	locale: string
	country: string
	countryName: string
	currency: string
	language: string

	/* Last PLP Context */
	plp: string // Full Path and Query String
	data: object // Previous Watches
	scroll: number // Scroll Position

	/* PLP Paging Context */
	showingX: number // Number of Items Showing
	showingY: number // Total Number of Items
	page: number // Current Page
	pages: number // Total Pages
	lastPage: boolean // Is Last Page
}
export type CustomerContextType = [
	CustomerContextValues,
	React.Dispatch<React.SetStateAction<CustomerContextValues>>,
]

/* Customer Context Provider */
export const CustomerContext = createContext<CustomerContextType | null>(null)

/* Customer Context Hook */
export const useCustomerContext = (): CustomerContextType => {
	const currentCustomerContext = useContext(CustomerContext)

	if (!currentCustomerContext) {
		throw new Error(
			'useCustomerContext has to be used within <CustomerContext.Provider>'
		)
	}

	return currentCustomerContext
}
