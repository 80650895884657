import { useEffect } from 'react'

export const useEscapeBadDomain = (): [] => {
	useEffect(() => {
		/* Client side? */
		if (typeof window !== 'undefined') {
			/* If domain is not allowed, escape to production */
			const domain = window.location.hostname
			const allowedDomains = [
				'localhost',
				'the1916company.com',
				'mobify-storefront.com',
				'commercecloud.salesforce.com',
			]
			if (
				allowedDomains.some(
					(allowedDomain) => domain.indexOf(allowedDomain) > -1
				) === false
			) {
				window.location.href =
					'https://www.the1916company.com/?utm_medium=referral&utm_campaign=proxy&utm_source=' +
					encodeURIComponent(domain)
				return
			}
		}
	}, [])
}
