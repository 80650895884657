/* Class for external detection */
const bodyFixedClass = 'body-fixed'

/* Local Helpers */
const setBodyProps = (add: boolean): void => {
	document.body.classList[add ? 'add' : 'remove'](bodyFixedClass)

	const props = add
		? {
				position: 'fixed',
				top: `-${window.scrollY || 0}px`,
				width: '100vw',
		  }
		: {
				position: '',
				top: '',
				width: 'auto',
		  }
	Object.assign(document.body.style, props)
}

/* Named functions */
export const bodyIsStuck = (): void => {
	setBodyProps(true)
}
export const bodyIsUnstuck = (): void => {
	const scrollY = parseInt(document.body?.style.top ?? 0)
	setBodyProps(false)
	if (!isNaN(scrollY)) {
		window?.scrollTo({
			top: scrollY * -1,
			left: 0,
			behavior: 'instant',
		})
	}
}
