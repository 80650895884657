import React, { useCallback, useState, useRef } from 'react'

/* Components */
import InlineCSS from '@components/seo/InlineCSS'

/* Helpers */
import { generateDataAttributes } from '@helpers/htmlHelpers'

/* Shared Types */
import { DataAttributes } from '@lib/types/DataAttributes.models'

/* Local Types */
export type BtnClickEventType =
	| React.MouseEvent<HTMLButtonElement>
	| React.TouchEvent<HTMLElement>
	| React.MouseEvent<HTMLDivElement>
interface BtnBaseProps {
	id?: string
	children: React.ReactNode
	handleClick?: (e?: BtnClickEventType) => void

	// Styles
	variant?: 'primary' | 'secondary' | 'subtle' | 'link' | 'icon'
	size?: 'sm' | 'md' | 'lg'

	// Attributes
	type?: 'button' | 'submit' | 'reset'
	className?: string // Implementation-specific classes for overrides & customizations
	rawclassname?: string // Raw classes for overrides & customizations
	disabled?: boolean

	ariaLabel?: string
	ariaControls?: string
	ariaExpanded?: boolean

	// Third-party data-attributes
	dataAttributes?: DataAttributes
}
type BtnProps = BtnBaseProps
interface touchingCoordsType {
	x: number
	y: number
	finalX: number
	finalY: number
}

/* Inline Styles */
import BtnStyles from '!raw-loader!postcss-loader!sass-loader!./Btn.module.scss'

export function Btn({
	id,
	children,
	handleClick,
	variant = 'primary',
	size = 'md',
	disabled = false,
	className,
	rawclassname,
	ariaLabel,
	ariaControls,
	ariaExpanded,
	dataAttributes,
}: BtnProps) {
	/* CSS Classes */
	const btnClasses = [
		'btn',
		size !== 'md' ? `btn--${size}` : '',
		variant !== 'primary' ? `btn--${variant}` : '',
	].join(' ')

	/* Any data attributes? */
	const dataAttr = dataAttributes ? generateDataAttributes(dataAttributes) : {}

	/* Ref */
	const ref = useRef<HTMLButtonElement>(null)

	/* Touching State */
	const [touching, setTouching] = useState(false)

	/* Movement State */
	const initialTouchingCoords: touchingCoordsType = {
		x: 0,
		y: 0,
		finalX: 0,
		finalY: 0,
	}
	const [touchingCoords, setTouchingCoords] = useState<touchingCoordsType>(
		initialTouchingCoords
	)

	/* Local Helpers */
	const calculateMovement = useCallback((): number => {
		return Math.sqrt(
			Math.pow(touchingCoords.finalX - touchingCoords.x, 2) +
				Math.pow(touchingCoords.finalY - touchingCoords.y, 2)
		)
	}, [touchingCoords])
	const isSameElement = (
		element: HTMLElement | null,
		target: HTMLElement | null
	): boolean => {
		return element &&
			target &&
			(element === target ||
				element.contains(target) ||
				target.contains(element))
			? true
			: false
	}

	const touchEvents = handleClick
		? {
				onTouchStart: (e: React.TouchEvent<HTMLElement>) => {
					const x: number = e.touches[0].clientX
					const y: number = e.touches[0].clientY
					setTouchingCoords({ x: x, y: y, finalX: x, finalY: y })
					setTouching(isSameElement(e.target as HTMLElement, ref.current))
				},
				onTouchMove: (e: React.TouchEvent<HTMLElement>) => {
					setTouchingCoords(
						Object.assign(touchingCoords, {
							finalX: e.touches[0].clientX,
							finalY: e.touches[0].clientY,
						})
					)
				},
				onTouchEnd: (e: React.TouchEvent<HTMLElement>) => {
					if (touching && isSameElement(e.target as HTMLElement, ref.current)) {
						e.preventDefault()
						e.stopPropagation()
						if (calculateMovement() < 32) {
							handleClick(e)
						}
					}
					setTouching(false)
					setTouchingCoords(initialTouchingCoords)
				},
		  }
		: {}

	return (
		<InlineCSS componentName={'Btn'} styles={BtnStyles}>
			<button
				// Identifiers
				id={id || undefined}
				className={btnClasses}
				rawclassname={[className || '', rawclassname || ''].join(' ').trim()}
				ref={ref}
				// Interaction
				disabled={disabled}
				onClick={
					handleClick
						? (e: React.MouseEvent<HTMLButtonElement>) => {
								handleClick(e)
						  }
						: undefined
				}
				{...touchEvents}
				// Aria Attributes
				aria-label={ariaLabel}
				aria-controls={ariaControls}
				aria-expanded={ariaExpanded}
				// Data Attributes
				{...dataAttr}
			>
				{children}
			</button>
		</InlineCSS>
	)
}
