import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

interface GTMScriptProps {
	gtmId: string
}

export default function GTMScript(props: GTMScriptProps): React.ReactElement {
	const { gtmId } = props

	/* Trigger gmt.start on first load */
	const [loaded, setLoaded] = useState<boolean>(false)
	useEffect(() => {
		if (!loaded) {
			window.dataLayer = window.dataLayer || []
			window.dataLayer.push({
				'gtm.start': new Date().getTime(),
				event: 'gtm.js',
			})
			setLoaded(true)
		}
	})

	return (
		<>
			<Helmet>
				<script
					id="gtm-script"
					src={`https://www.googletagmanager.com/gtm.js?id=${gtmId}`}
				></script>
			</Helmet>
		</>
	)
}
