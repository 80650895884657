import { useEffect, useState } from 'react'

/* Hook */
export const useIsWiderThan = (width: number): boolean => {
	const [useIsWiderThan, setIsWiderThan] = useState<boolean>(false)

	useEffect(() => {
		const checkIt = () => {
			setIsWiderThan(window.innerWidth > width)
		}
		checkIt()
		window.addEventListener('resize', checkIt)
		return () => {
			window.removeEventListener('resize', checkIt)
		}
	}, [])

	return useIsWiderThan
}

/* Fixed variation of hook */
export const useIsDesktop = (): boolean => {
	return useIsWiderThan(1280)
}
