import React from 'react'
import { JsonLd } from 'react-schemaorg'
import { BreadcrumbList } from 'schema-dts'

/* Local Types */
export interface CrumbsSchemaProps {
	crumbs: CrumbsSchemaType[]
}
export interface CrumbsSchemaType {
	name: string
	url: string
}

const CrumbsSchema = ({ crumbs }: CrumbsSchemaProps) => {
	return (
		<JsonLd<BreadcrumbList>
			item={{
				'@context': 'https://schema.org',
				'@type': 'BreadcrumbList',
				itemListElement: crumbs.map(({ name, url }, index) => ({
					'@type': 'ListItem',
					position: index + 1,
					item: {
						'@id': url,
						name: name,
					},
				})),
			}}
		/>
	)
}

export default CrumbsSchema
