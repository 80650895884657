import React from 'react'

/* Components */
import CriteoClient from './CriteoClient'

const Criteo = ({ criteoId }: { criteoId: string }): React.ReactElement => {
	return <>{criteoId && <CriteoClient criteoId={criteoId} />}</>
}

export default Criteo
