import React from 'react'

/* All third party scripts */
import AW from './AW/AW'
import GTM from './GTM/GTM'
import OneTrust from './OneTrust/OneTrust'
import AudioEye from './AudioEye/AudioEye'
import VWO from './VWO/VWO'
import Criteo from './Criteo/Criteo'

/* Local Types */
interface thirdPartyType {
	[key: string]: boolean | string
}

const ThirdParty = ({
	thirdParty,
}: {
	thirdParty: thirdPartyType
}): React.ReactElement => {
	return (
		<>
			{thirdParty['AW'] && <AW awId={thirdParty['AW_ID'] as string} />}
			{thirdParty['GTM'] && <GTM gtmId={thirdParty['GTM_ID'] as string} />}
			{thirdParty['ONETRUST'] && (
				<OneTrust oneTrustId={thirdParty['ONETRUST_ID'] as string} />
			)}
			{thirdParty['AUDIOEYE'] && <AudioEye />}
			{thirdParty['CRITEO'] && (
				<Criteo criteoId={thirdParty['CRITEO_ID'] as string} />
			)}
			{thirdParty['VWO'] && (
				<VWO
					vwoId={thirdParty['VWO_ID'] as string}
					vwoAlwaysOn={thirdParty['VWO_ALWAYS_ON'] as boolean}
				/>
			)}
		</>
	)
}

export default ThirdParty
