import React, { useState, useEffect, useRef } from 'react'

/* Components */
import BannerPicture, {
	BannerPicturePicture,
} from '@components/banners/common/BannerPicture'

/* Helpers */
import { generateCSSClassString } from '@helpers/cssHelpers/index'

/* Styles */
import IntroBannerStyles from './IntroBanner.module.scss'

/* Local Types */
interface IntroBannerInfo {
	pictureDesktop: BannerPicturePicture
	pictureMobile: BannerPicturePicture
	alt: string
}

interface IntroBannerBulletInfo extends IntroBannerInfo {
	headline: string
	text: string
}

const IntroBanner = ({
	title,
	main,
	bullets,
}: {
	title: string
	main: IntroBannerInfo
	bullets: IntroBannerBulletInfo[]
}) => {
	const [currentlySelectedBulletIndex, setCurrentlySelectedBulletIndex] =
		useState(-1)
	const [
		currentlySelectedMobileItemIndex,
		setCurrentlySelectedMobileItemIndex,
	] = useState(-1)
	const bannerRef = useRef(null)

	/* Autoplay Effect Desktop */
	useEffect(() => {
		let autoPlayInterval: NodeJS.Timeout

		const observer = new IntersectionObserver((entries) => {
			// If the component is in view, start the interval
			if (entries[0].isIntersecting) {
				autoPlayInterval = setInterval(() => {
					setCurrentlySelectedBulletIndex((prevIndex) =>
						prevIndex === bullets.length - 1 ? -1 : prevIndex + 1
					)
				}, 5000)
			} else {
				// If the component is not in view, clear the interval
				clearInterval(autoPlayInterval)
			}
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current)
		}

		return () => {
			observer.disconnect()
			clearInterval(autoPlayInterval)
		}
	}, [currentlySelectedBulletIndex])

	/* Autoplay Effect Mobile */
	useEffect(() => {
		let autoPlayInterval: NodeJS.Timeout

		const observer = new IntersectionObserver((entries) => {
			// If the component is in view, start the interval
			if (entries[0].isIntersecting) {
				autoPlayInterval = setInterval(() => {
					setCurrentlySelectedMobileItemIndex((prevIndex) =>
						prevIndex === bullets.length - 1 ? -1 : prevIndex + 1
					)
				}, 5000)
			} else {
				// If the component is not in view, clear the interval
				clearInterval(autoPlayInterval)
			}
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current)
		}

		return () => {
			observer.disconnect()
			clearInterval(autoPlayInterval)
		}
	}, [currentlySelectedMobileItemIndex])

	return (
		<div ref={bannerRef}>
			<div className={`${IntroBannerStyles['desktop-container']}`}>
				<div className={`${IntroBannerStyles['desktop-image-container']}`}>
					<div
						style={{ opacity: currentlySelectedBulletIndex === -1 ? 1 : 0 }}
						className={`${generateCSSClassString(
							['desktop-image'],
							IntroBannerStyles
						)}`}
					>
						<BannerPicture
							pictureDesktop={main.pictureDesktop}
							pictureMobile={main.pictureMobile}
							alt={main.alt}
						/>
					</div>

					{bullets.map((bullet, index) => (
						<div
							key={bullet.headline}
							className={`${generateCSSClassString(
								[
									'desktop-image',
									index === currentlySelectedBulletIndex
										? 'desktop-image--show'
										: '',
								],
								IntroBannerStyles
							)}`}
							style={{ zIndex: index + 1 }}
						>
							<BannerPicture
								pictureDesktop={bullets[index].pictureDesktop}
								pictureMobile={bullets[index].pictureMobile}
								alt={bullets[index].alt}
							/>
						</div>
					))}
				</div>
				<div className={`${IntroBannerStyles['desktop-items']}`}>
					<h2 className={`${IntroBannerStyles['desktop-items__title']}`}>
						{title}
					</h2>
					<div className={`${IntroBannerStyles['desktop-items__set']}`}>
						{bullets.map((bullet, index) => (
							<div
								key={bullet.headline}
								className={`${generateCSSClassString(
									[
										'desktop-items__set__item',
										index === currentlySelectedBulletIndex
											? 'desktop-items__set__item--is-active'
											: '',
									],
									IntroBannerStyles
								)}`}
								onClick={() =>
									setCurrentlySelectedBulletIndex(
										index === currentlySelectedBulletIndex ? -1 : index
									)
								}
							>
								<h3
									className={`${generateCSSClassString(
										[
											'desktop-items__set__item__headline',
											index === currentlySelectedBulletIndex
												? 'desktop-items__set__item__headline--is-active'
												: '',
										],
										IntroBannerStyles
									)}`}
								>
									{bullet.headline}
								</h3>
								<p
									className={`${generateCSSClassString(
										[
											'desktop-items__set__item__text',
											index === currentlySelectedBulletIndex
												? 'desktop-items__set__item__text--is-active'
												: '',
										],
										IntroBannerStyles
									)}`}
								>
									{bullet.text}
								</p>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className={`${IntroBannerStyles['mobile-container']}`}>
				<div className={`${IntroBannerStyles['mobile-image-container']}`}>
					<div
						style={{ opacity: currentlySelectedMobileItemIndex === -1 ? 1 : 0 }}
						className={`${generateCSSClassString(
							['mobile-image'],
							IntroBannerStyles
						)}`}
					>
						<BannerPicture
							pictureDesktop={main.pictureDesktop}
							pictureMobile={main.pictureMobile}
							alt={main.alt}
						/>
					</div>

					{bullets.map((bullet, index) => (
						<div
							key={bullet.headline}
							className={`${generateCSSClassString(
								[
									'mobile-image',
									index === currentlySelectedMobileItemIndex
										? 'mobile-image--show'
										: '',
								],
								IntroBannerStyles
							)}`}
							style={{ zIndex: index + 1 }}
						>
							<BannerPicture
								pictureDesktop={bullets[index].pictureDesktop}
								pictureMobile={bullets[index].pictureMobile}
								alt={bullets[index].alt}
							/>
						</div>
					))}
				</div>
				<div className={`${IntroBannerStyles['mobile-items']}`}>
					<h2 className={`${IntroBannerStyles['mobile-items__title']}`}>
						{title}
					</h2>
					<div className={`${IntroBannerStyles['mobile-items__set']}`}>
						{bullets.map((bullet, index) => (
							<div
								key={bullet.headline}
								className={`${generateCSSClassString(
									[
										'mobile-items__set__item',
										index === currentlySelectedMobileItemIndex
											? 'mobile-items__set__item--is-active'
											: '',
									],
									IntroBannerStyles
								)}`}
								onClick={() =>
									setCurrentlySelectedMobileItemIndex(
										index === currentlySelectedMobileItemIndex ? -1 : index
									)
								}
							>
								<h3
									className={`${generateCSSClassString(
										[
											'mobile-items__set__item__headline',
											index === currentlySelectedMobileItemIndex
												? 'mobile-items__set__item__headline--is-active'
												: '',
										],
										IntroBannerStyles
									)}`}
								>
									{bullet.headline}
								</h3>
								{currentlySelectedMobileItemIndex === index ? (
									<p
										className={`${IntroBannerStyles['mobile-items__set__item__text']}`}
									>
										{bullet.text}
									</p>
								) : null}
							</div>
						))}
					</div>
				</div>
			</div>
		</div>
	)
}

export default IntroBanner
