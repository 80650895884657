import React, { FC } from 'react'
import Script from '@core/script'

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Local Types */
interface AudioEyeClientProps {
	config: {
		allowBots: boolean
		url: string
	}
}

const AudioEyeClient: FC<AudioEyeClientProps> = ({
	config,
}): React.ReactElement => {
	const isHuman = useDetectHuman()
	return <>{(config.allowBots || isHuman) && <Script src={config.url} />}</>
}

export default AudioEyeClient
