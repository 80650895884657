import React, { FC } from 'react'
import { usePathname, useQueryParams } from '@next/navigation'

/* Components */
import GTMScript from './GTMScript'
import GTMNoScript from './GTMNoScript'

/* Hooks */
import { useDataLayer, useGTAGEvent } from '@helpers/hooksHelper'
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Local Types */
interface GTMProps {
	gtmId: string
}

const GTM: FC<GTMProps> = (props): React.ReactElement => {
	const { gtmId } = props

	/* Track page navigation */
	const pathname = usePathname()
	const searchParams = useQueryParams()

	// For extended usage, define as:
	//    const [isHuman, setIsHuman] = useDetectHuman()
	//    const [isDataLayer, setIsDataLayer] = useDataLayer(id)

	/* Initialize GTM */
	const isHuman = useDetectHuman()
	useDataLayer(gtmId)
	useGTAGEvent(pathname, searchParams?.toString())

	/* Local Component */
	const getGoogleTagManager = (gtmId: string) => {
		return (
			<>
				<GTMScript gtmId={gtmId} />
				<GTMNoScript gtmId={gtmId} />
			</>
		)
	}

	return <>{gtmId && isHuman && getGoogleTagManager(gtmId)}</>
}

export default GTM
