import React from 'react'

/* Components */
import BannerPicture, { BannerPictureProps } from '../common/BannerPicture'
import PlayIcon from './PlayIcon'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { replaceEscapedNewlines } from '@helpers/strapiHelper'

/* Styles */
import PlayBannerStyles from './PlayBanner.module.scss'

/* Local Types */
interface PlayBanner {
	picture: BannerPictureProps
	playButtonHref: string
	title?: string
	linkName?: string
	linkHref?: string
	// Color Stuff
	titleColor?: string
	ctaColor?: string
	ctaBackgroundColor?: string
}

const PlayBanner = ({ info }: { info: PlayBanner }) => {
	/* Local Components */
	const PrimaryLink = (
		<SwitchLink
			href={info.linkHref}
			target="_blank"
			style={{
				borderColor: info.ctaColor || '#fff',
				color: info.ctaColor || '#fff',
			}}
			className={`${PlayBannerStyles['cta-button']} ${PlayBannerStyles['cta-button__text']}`}
		>
			{info.linkName}
		</SwitchLink>
	)

	const DesktopItems = (
		<div className={`${PlayBannerStyles['desktop-items']}`}>
			{info.title ? (
				<h3
					style={{ color: info.titleColor || '#fff' }}
					className={`${PlayBannerStyles['desktop-items__cta-title']}`}
				>
					{replaceEscapedNewlines(info.title)}
				</h3>
			) : null}
			{info.linkHref && <div style={{ marginLeft: '5rem' }}>{PrimaryLink}</div>}
		</div>
	)

	const MobileItems = (
		<div className={`${PlayBannerStyles['mobile-items']}`}>
			<div>
				<div className={`${PlayBannerStyles['mobile-items__cta-flex']}`}>
					{info.title ? (
						<h3
							style={{ color: info.titleColor || '#fff' }}
							className={`${PlayBannerStyles['mobile-items__cta-title']}`}
						>
							{replaceEscapedNewlines(info.title)}
						</h3>
					) : null}
					{info.linkHref && (
						<div className={`${PlayBannerStyles['mobile-items__cta-link']}`}>
							{PrimaryLink}
						</div>
					)}
				</div>
			</div>
		</div>
	)

	return (
		<div className={`${PlayBannerStyles['container']}`}>
			<div className={`${PlayBannerStyles['image-container']}`}>
				<BannerPicture
					pictureDesktop={info.picture.pictureDesktop}
					pictureMobile={info.picture.pictureMobile}
					alt={info.picture.alt}
				/>
			</div>
			{DesktopItems}
			{MobileItems}
			<div className={`${PlayBannerStyles['play-button']}`}>
				<PlayIcon linkHref={info.playButtonHref} />
			</div>
		</div>
	)
}

export default PlayBanner
