/* Global click counter example */
/*
    import { incrementGlobalState } from '@lib/state/global.ts';

    useEffect(() => {
        const incrementClick = () => {
            incrementGlobalState('clicks', true);
        };
        document.body.addEventListener('click', incrementClick);
        return () => {
            document.body.removeEventListener('click', incrementClick);
        };
    }, []);
*/

/* Sync local state with global state */
/*
    import { setGlobalState, resetGlobalState } from '@lib/state/global.ts';

    const [localState, setLocalState] = useState({ componentName: initialValues });

    useEffect(() => {
        return () => {
            resetGlobalState('componentName');
        };
    }, []);

    useEffect(() => {
        setLocalState(getGlobalState());
    }, [localState]);
*/

/* Default Global State */
let globalState: GlobalState = {}

/* Local Types */
type GlobalStateType = string | number | boolean | undefined
interface GlobalState {
	[key: string]: GlobalStateType
}

/* Global Types */
declare const window: {
	currentGlobalState?: GlobalState
	updateGlobalState?: (state: GlobalState) => GlobalState
} & Window

/* In browser console: currentGlobalState */
const syncState = (): GlobalState => {
	if (typeof window !== 'undefined') {
		window.currentGlobalState = globalState

		/* For localhost debugging only: updateGlobalState({'test': '1'}); */
		// window.updateGlobalState = setGlobalState;
	}
	return globalState
}

/* Set and Get Global State */
export const setGlobalState = (state: GlobalState): GlobalState => {
	globalState = { ...globalState, ...state }
	return syncState()
}
export const getGlobalState = (): GlobalState => {
	globalState = globalState || {}
	return syncState()
}
export const incrementGlobalState = (key: string): GlobalState => {
	globalState[key] = ((globalState?.[key] || 0) as number) + 1
	return syncState()
}
export const resetGlobalState = (key: string): GlobalState => {
	if (key in globalState) {
		delete globalState[key]
	}
	return syncState()
}
