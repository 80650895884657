export const setCSS = (
	elem: HTMLElement | null,
	attr: string,
	value: string
): void => {
	elem?.style.setProperty(attr, value)
}
export const setClass = (
	elem: HTMLElement | null,
	className: string,
	add: boolean
): void => {
	elem?.classList[add ? 'add' : 'remove'](className)
}
export const setExpanded = (
	elem: HTMLElement | null,
	expanded: boolean
): void => {
	elem?.setAttribute('aria-expanded', expanded.toString())
}
export const setAriaPressed = (
	elem: HTMLElement | null,
	pressed: boolean
): void => {
	elem?.setAttribute('aria-pressed', pressed.toString())
}
export const setDocumentEvents = (
	events: string[],
	callback: EventListenerOrEventListenerObject,
	add: boolean
): void => {
	events.forEach((event) => {
		document?.[add ? 'addEventListener' : 'removeEventListener'](
			event,
			callback
		)
	})
}
export const setEvent = (
	elem: HTMLElement | null,
	event: string,
	callback: EventListenerOrEventListenerObject,
	add: boolean
): void => {
	elem?.[add ? 'addEventListener' : 'removeEventListener'](event, callback)
}
