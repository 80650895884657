/* Hooks */
import { hookNav } from '@helpers/navHelper/navHelper.hooks'

/* PWA Hooks */
import { getAppOrigin } from '@salesforce/pwa-kit-react-sdk/utils/url'

/* Shared Types */
import { NavigationData } from '@lib/mock/nav.models'

export const use1916Categories = async (): Promise<NavigationData> => {
	return await fetch(getAppOrigin() + '/api/category/root/?levels=2')
		.then(async (res) => {
			return hookNav(await res.json(), {})
		})
		.catch((err) => {
			console.error(err)
			return {} as NavigationData
		})
}
