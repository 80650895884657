import React from 'react'
import { Helmet } from 'react-helmet'

/* Helpers */
import { getIconMetas } from '@helpers/faviconHelper'

/* Shared Types */
import { iconType } from '@helpers/faviconHelper/faviconHelper.models'

/* Fixed Config */
const favIconRoot: string = 'https://the1916company.imgix.net/favicons/'

/* Local Helpers */
const getIconPath = (icon: iconType): iconType => {
	const { href, ...rest } = icon
	return {
		href: favIconRoot + href,
		...rest,
	}
}

const Favicon = () => {
	const metas = getIconMetas()
	return (
		<>
			<Helmet>
				{metas.icons.icon.map((icon, index) => (
					<link key={'icon-' + index} {...getIconPath(icon)} />
				))}
				{metas.icons.apple.map((icon, index) => (
					<link key={'apple-' + index} {...getIconPath(icon)} />
				))}
				{Object.keys(metas.other).map((key, index) => (
					<meta key={'other-' + index} name={key} content={metas.other[key]} />
				))}
			</Helmet>
		</>
	)
}

export default Favicon
