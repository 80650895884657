import React, { FC } from 'react'

/* Components */
import AudioEyeServer from './AudioEyeServer'
import AudioEyeClient from './AudioEyeClient'

/* Local Types */
interface configType {
	allowBots: boolean
	url: string
	hash: string
}

/* Local Config */
const config: configType = {
	allowBots: false,
	url: 'https://wsmcdn.audioeye.com/aem.js',
	hash: '13b4dd5d8118673093b0ac301cfc8304',
}

const AudioEye: FC = (): React.ReactElement => {
	return (
		<>
			<AudioEyeServer config={config} />
			<AudioEyeClient config={config} />
		</>
	)
}

export default AudioEye
