import React, { useCallback, useEffect, useRef, useState } from 'react'

/* Components */
import BannerPicture, { BannerPictureProps } from '../common/BannerPicture'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { generateCSSClassString } from '@helpers/cssHelpers/index'
import { convertPositionValue } from '@helpers/strapiHelper'
import { getImgixURLAtWidth } from '@helpers/imgixHelper'

/* Styles */
import BrandsBannerStyles from './BrandsBanner.module.scss'

/* Local Types */
interface BrandsBannerPosition {
	top?: number
	bottom?: number
	left?: number
	right?: number
}

interface BrandsBannerInfo {
	id: number
	picture: BannerPictureProps
	ctaTitle: string
	ctaTitleColor?: string
	ctaBackgroundColor?: string
	ctaBorderColor?: string
	brandLogoSrc: string
	brandLogoHeight: number
	brandLogoWidth: number
	brandLogoAlt: string
	buttonBrandLogoSrc: string
	buttonBrandLogoHeight: number
	buttonBrandLogoWidth: number
	buttonBrandLogoAlt: string
	desktopCtaPosition: BrandsBannerPosition
	desktopBrandLogoPosition: BrandsBannerPosition
	mobileCtaPosition: BrandsBannerPosition
	mobileBrandLogoPosition: BrandsBannerPosition
	desktopHorizontalPaddingCTA: number
	mobileHorizontalPaddingCTA: number
	desktopBrandLogoContainerWidth: number
	mobileBrandLogoContainerWidth: number
	ctaLinkHref: string
	// Brand Button
	desktopButtonLogoContainerWidth: number
	desktopButtonSelectedBarWidth: number
	mobileButtonLogoContainerWidth: number
	mobileButtonSelectedBarWidth: number
	desktopButtonPosition: BrandsBannerPosition
	mobileButtonPosition: BrandsBannerPosition
}

const defaultBrandLogoAlt: string = 'Brand Logo'

/* Local Child Component */
const BrandContainer = ({ info }: { info: BrandsBannerInfo }) => {
	/* Local Components */
	const PrimaryLink = ({
		horizontalPadding,
	}: {
		horizontalPadding: number
	}) => (
		<SwitchLink
			href={info.ctaLinkHref}
			style={{
				paddingLeft: `${horizontalPadding}px`,
				paddingRight: `${horizontalPadding}px`,
				borderColor: info.ctaBorderColor || '#fff',
				color: info.ctaTitleColor || '#fff',
				backgroundColor: info.ctaBackgroundColor || '#000',
			}}
			className={`${BrandsBannerStyles['cta-button']} ${BrandsBannerStyles['cta-button__text']}`}
		>
			{info.ctaTitle}
			{info.brandLogoAlt && (
				<span className={`${BrandsBannerStyles['seo']}`}>
					{' '}
					- {info.brandLogoAlt}
				</span>
			)}
		</SwitchLink>
	)

	const DesktopCTA = (
		<div
			className={`${BrandsBannerStyles['desktop-cta']}`}
			style={{
				top: convertPositionValue(info.desktopCtaPosition.top),
				bottom: convertPositionValue(info.desktopCtaPosition.bottom),
				left: convertPositionValue(info.desktopCtaPosition.left),
				right: convertPositionValue(info.desktopCtaPosition.right),
			}}
		>
			{PrimaryLink({ horizontalPadding: info.desktopHorizontalPaddingCTA })}
		</div>
	)

	const MobileCTA = (
		<div
			className={`${BrandsBannerStyles['mobile-cta']}`}
			style={{
				top: convertPositionValue(info.mobileCtaPosition.top),
				bottom: convertPositionValue(info.mobileCtaPosition.bottom),
				left: convertPositionValue(info.mobileCtaPosition.left),
				right: convertPositionValue(info.mobileCtaPosition.right),
			}}
		>
			{PrimaryLink({ horizontalPadding: info.mobileHorizontalPaddingCTA })}
		</div>
	)

	const DesktopBrandLogo = (
		<div
			className={`${BrandsBannerStyles['desktop-brand-logo']}`}
			style={{
				top: convertPositionValue(info.desktopBrandLogoPosition.top),
				bottom: convertPositionValue(info.desktopBrandLogoPosition.bottom),
				left: convertPositionValue(info.desktopBrandLogoPosition.left),
				right: convertPositionValue(info.desktopBrandLogoPosition.right),
			}}
		>
			<img
				src={getImgixURLAtWidth(
					info.brandLogoSrc,
					info.desktopBrandLogoContainerWidth
				)}
				alt={info.brandLogoAlt || defaultBrandLogoAlt}
				height={info.brandLogoHeight}
				width={info.brandLogoWidth}
				loading={'lazy'}
				style={{
					objectFit: 'contain',
					width: `${info.desktopBrandLogoContainerWidth}px`,
					height: 'auto',
				}}
			/>
		</div>
	)

	const MobileBrandLogo = (
		<div
			className={`${BrandsBannerStyles['mobile-brand-logo']}`}
			style={{
				top: convertPositionValue(info.mobileBrandLogoPosition.top),
				bottom: convertPositionValue(info.mobileBrandLogoPosition.bottom),
				left: convertPositionValue(info.mobileBrandLogoPosition.left),
				right: convertPositionValue(info.mobileBrandLogoPosition.right),
			}}
		>
			<img
				src={getImgixURLAtWidth(
					info.brandLogoSrc,
					info.mobileBrandLogoContainerWidth
				)}
				alt={info.brandLogoAlt || defaultBrandLogoAlt}
				height={info.brandLogoHeight}
				width={info.brandLogoWidth}
				loading={'lazy'}
				style={{
					objectFit: 'contain',
					width: `${info.mobileBrandLogoContainerWidth}px`,
					height: 'auto',
				}}
			/>
		</div>
	)

	return (
		<div>
			<div className={`${BrandsBannerStyles['image-container']}`}>
				<BannerPicture
					pictureDesktop={info.picture.pictureDesktop}
					pictureMobile={info.picture.pictureMobile}
					alt={info.picture.alt || 'Image'}
				/>
			</div>
			{DesktopCTA}
			{DesktopBrandLogo}
			{MobileCTA}
			{MobileBrandLogo}
		</div>
	)
}

const BrandsBanner = ({
	interval = 6,
	infoItems,
}: {
	interval?: number
	infoItems: BrandsBannerInfo[]
}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const bannerRef = useRef(null)

	/* Local Callbacks */
	const nextItem = useCallback(() => {
		setActiveIndex((prevIndex) =>
			prevIndex === infoItems.length - 1 ? 0 : prevIndex + 1
		)
	}, [infoItems.length])

	const goToItem = (index: number) => {
		setActiveIndex(index)
	}

	/* Autoplay Effect */
	useEffect(() => {
		let autoPlayInterval: NodeJS.Timeout

		const observer = new IntersectionObserver((entries) => {
			// If the component is in view, start the interval
			if (entries[0].isIntersecting) {
				autoPlayInterval = setInterval(nextItem, interval * 1000)
			} else {
				// If the component is not in view, clear the interval
				clearInterval(autoPlayInterval)
			}
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current)
		}

		return () => {
			observer.disconnect()
			clearInterval(autoPlayInterval)
		}
	}, [activeIndex, interval, nextItem])

	/* Local Components */
	const renderDesktopButton = ({
		infoItem,
		index,
	}: {
		infoItem: BrandsBannerInfo
		index: number
	}) => {
		const alt: string = infoItem.buttonBrandLogoAlt || defaultBrandLogoAlt
		return (
			<button
				key={infoItem.id}
				onClick={() => goToItem(index)}
				className={`${BrandsBannerStyles['desktop-items__button']}`}
				aria-label={alt}
			>
				<div
					className={`${BrandsBannerStyles['desktop-items__button__item']}`}
					style={{
						top: convertPositionValue(infoItem.desktopButtonPosition.top),
						bottom: convertPositionValue(infoItem.desktopButtonPosition.bottom),
						left: convertPositionValue(infoItem.desktopButtonPosition.left),
						right: convertPositionValue(infoItem.desktopButtonPosition.right),
					}}
				>
					<img
						src={getImgixURLAtWidth(
							infoItem.buttonBrandLogoSrc,
							infoItem.desktopButtonLogoContainerWidth
						)}
						alt={alt}
						height={infoItem.buttonBrandLogoHeight}
						width={infoItem.buttonBrandLogoWidth}
						loading={'lazy'}
						style={{
							objectFit: 'contain',
							width: `${infoItem.desktopButtonLogoContainerWidth}px`,
							height: 'auto',
						}}
					/>
					<div
						style={{ width: infoItem.desktopButtonSelectedBarWidth }}
						className={`${generateCSSClassString(
							[
								'desktop-items__button__item__selection',
								index === activeIndex
									? 'desktop-items__button__item__selection--show'
									: '',
							],
							BrandsBannerStyles
						)}`}
					/>
				</div>
			</button>
		)
	}

	const renderMobileButton = ({
		infoItem,
		index,
	}: {
		infoItem: BrandsBannerInfo
		index: number
	}) => {
		const alt: string = infoItem.buttonBrandLogoAlt || defaultBrandLogoAlt
		return (
			<button
				key={infoItem.id}
				onClick={() => goToItem(index)}
				className={`${BrandsBannerStyles['mobile-items__button']}`}
				aria-label={alt}
			>
				<div
					className={`${BrandsBannerStyles['mobile-items__button__item']}`}
					style={{
						top: convertPositionValue(infoItem.mobileButtonPosition.top),
						bottom: convertPositionValue(infoItem.mobileButtonPosition.bottom),
						left: convertPositionValue(infoItem.mobileButtonPosition.left),
						right: convertPositionValue(infoItem.mobileButtonPosition.right),
					}}
				>
					<img
						src={getImgixURLAtWidth(
							infoItem.buttonBrandLogoSrc,
							infoItem.mobileButtonLogoContainerWidth
						)}
						alt={alt}
						height={infoItem.buttonBrandLogoHeight}
						width={infoItem.buttonBrandLogoWidth}
						loading={'lazy'}
						style={{
							objectFit: 'contain',
							width: `${infoItem.mobileButtonLogoContainerWidth}px`,
							height: 'auto',
						}}
					/>
					<div
						style={{ width: infoItem.mobileButtonSelectedBarWidth }}
						className={`${generateCSSClassString(
							[
								'mobile-items__button__item__selection',
								index === activeIndex
									? 'mobile-items__button__item__selection--show'
									: '',
							],
							BrandsBannerStyles
						)}`}
					/>
				</div>
			</button>
		)
	}

	return (
		<div ref={bannerRef}>
			<div className={`${BrandsBannerStyles['desktop-container']}`}>
				<div className={`${BrandsBannerStyles['desktop-items']}`}>
					{infoItems.map((infoItem, index) => {
						return renderDesktopButton({ infoItem, index })
					})}
				</div>
				<div className={`${BrandsBannerStyles['desktop-banner-container']}`}>
					{infoItems.map((info, index) => (
						<div
							key={info.id}
							className={`${generateCSSClassString(
								[
									'desktop-banner',
									index === activeIndex ? 'desktop-banner--show' : '',
								],
								BrandsBannerStyles
							)}`}
						>
							<BrandContainer info={info} />
						</div>
					))}
				</div>
			</div>
			<div className={`${BrandsBannerStyles['mobile-container']}`}>
				<div className={`${BrandsBannerStyles['mobile-banner-container']}`}>
					{infoItems.map((info, index) => (
						<div
							key={info.id}
							className={`${generateCSSClassString(
								[
									'mobile-banner',
									index === activeIndex ? 'mobile-banner--show' : '',
								],
								BrandsBannerStyles
							)}`}
						>
							<BrandContainer info={info} />
						</div>
					))}
				</div>
				<div className={`${BrandsBannerStyles['mobile-items-container']}`}>
					<div className={`${BrandsBannerStyles['mobile-items']}`}>
						{infoItems.map((infoItem, index) => {
							return renderMobileButton({ infoItem, index })
						})}
					</div>
				</div>
			</div>
		</div>
	)
}

export default BrandsBanner
