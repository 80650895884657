/* Known SFRA Routes */
const routesSFRA = [
	/* Catalog */
	'rolex',
	'rolex-certified-pre-owned',

	/* Header */
	'account',
	'login',
	'wishlist',
	'bag',
	'checkout',
	'live',

	/* Footer */
	'sell-and-trade',
	'sell-your-watch',
	'trade-your-watch',
	'sell-quote',
	'trade-quote',
	'warranties',
	'contact-us',

	/* About Us Pages */
	'about-us/our-team',
	'about-us/our-locations',
	'about-us/our-locations/multi-brand-showrooms',
]

export const useKnownSFRA = (href: string | undefined): boolean => {
	/* No link? */
	if (!href) {
		return false
	}

	/* Determine up-level path */
	let safeHref: string = href
	if (safeHref.indexOf('..') > -1) {
		['watches', 'pre-owned'].forEach((route) => {
			safeHref = safeHref.replace(`/${route}/../`, `/`)
		})
	}

	/* Determine top-level path */
	if (safeHref.indexOf('../../') > -1) {
		safeHref = '/' + safeHref.split('../../').pop() + '/' || ''
		safeHref = safeHref.replace(/\.\.\//g, '/').replace(/\/{2,}/g, '/')
	}

	return routesSFRA.some((route) => safeHref.startsWith(`/${route}/`))
		? true
		: false
}
