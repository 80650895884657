import { createContext, useContext } from 'react'
import { useStateChangeAttempt } from '@hooks/useStateChangeAttempt'

/* Local Types */
type IndexContextValues = {
	index: number | string | null
	scroll: number | null
}
export type IndexContextType =
	| [
			IndexContextValues,
			React.Dispatch<React.SetStateAction<IndexContextValues>>,
	  ]
	| []

/* Index Context Provider */
export const IndexContext = createContext<IndexContextType | []>([])

/* Index Context Hook */
export const useIndexContext = (): IndexContextType => {
	return useContext(IndexContext)
}

export const useIndex = (): IndexContextType => {
	return useStateChangeAttempt({
		index: '',
		scroll: -1,
	}) as IndexContextType
}
