import React from 'react'
import { Helmet } from 'react-helmet'

interface ScriptProps {
	src: string
	type?: string
	async?: boolean
	id?: string
}

const Script = ({
	src,
	type,
	async,
	...rest
}: ScriptProps): React.ReactElement => {
	return (
		<Helmet>
			<script async={async} type={type} src={src} {...rest} />
		</Helmet>
	)
}

export default Script
