/* Sentry */
import { init, reactRouterV5BrowserTracingIntegration } from '@sentry/react'
import { Event } from '@sentry/types'
import { createBrowserHistory } from 'history'

/* PWA Hooks */
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'

export const useEnableSentry = () => {
	const sentry = getConfig()?.app?.sentry || {}

	/* React Router V5 Integration -- https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/ */
	const history = createBrowserHistory()

	const SENTRY_DSN: string = sentry?.dsn || ''
	const SENTRY_ENABLED: boolean = SENTRY_DSN ? sentry?.enabled || false : false

	if (SENTRY_DSN && SENTRY_ENABLED) {
		const SENTRY_SAMPLE_RATE: number = sentry?.sampleRate || 0.1
		const SENTRY_ENV: string = sentry?.env || 'unknown'
		const SENTRY_DEBUG: boolean = false

		/* Allow more errors in lower environments */
		const isLowerEnvironment: boolean =
			SENTRY_ENV.indexOf('staging') > -1 || SENTRY_ENV.indexOf('uat') > -1
				? true
				: false

		/* Skip errors from bots */
		const skipBrowsers = (name: string | undefined): boolean => {
			const agentName: string = name?.toLowerCase().replace(/\s/g, '') || ''
			return agentName &&
				['headlesschrome', 'googlebot', 'bingbot'].some(
					(bot) => agentName.indexOf(bot) > -1
				)
				? true
				: false
		}

		/* Skip errors from specific events */
		const skipEvent = (value: string | undefined): boolean => {
			const skipStrings: string[] = [
				'cookiedeprecationlabel',
				'sessionstorage is disabled',
				'loading css chunk',
				'autofillcallbackhandler',
				'object not found',
				'logmutedmessage',
				'iframemessagehandler',
				'canvasrenderingcontext2d',
			]

			/* Mute some production errors */
			if (!isLowerEnvironment) {
				skipStrings.push(
					'the operation is',
					'finally is not',
					'missing part #2',
					'illegal invocation',
					'failed to read the'
				)
			}
			return (
				!value ||
				typeof value !== 'string' ||
				value === 'undefined' ||
				['403', '400'].includes(value) ||
				skipStrings.some(
					(skipString) => value.toLowerCase().indexOf(skipString) > -1
				)
			)
		}

		/* Skip translation errors */
		const skipTranslation = (value: string | undefined): boolean => {
			const skipStrings: string[] = ['starttransition', 'removechild']
			return value &&
				typeof value === 'string' &&
				skipStrings.some(
					(skipString) => value.toLowerCase().indexOf(skipString) > -1
				)
				? true
				: false
		}

		/* Skip errors from specific filenames */
		const skipFrame = (event: Event): boolean => {
			const skipsFrames: string[] = [
				'tangiblee-bundle.min.js',
				'otBannerSdk.js',
				'otSDKStub.js',
				'startup.bundle',
			]
			return skipsFrames.some(
				(skip) =>
					event?.exception?.values?.[0]?.stacktrace?.frames?.some(
						(frame) => frame.filename && frame.filename.indexOf(skip) > -1
					)
			)
				? true
				: false
		}

		/* Skip errors from specific plugins */
		const skipPlugins = (value: string | undefined): boolean => {
			return value &&
				['chrome-extension://', 'pptr://'].some(
					(skipString) => value?.indexOf(skipString) > -1
				)
				? true
				: false
		}

		let ignoreError: boolean = false

		init({
			dsn: SENTRY_DSN,
			debug: SENTRY_DEBUG,
			environment: SENTRY_ENV,
			tracesSampleRate: SENTRY_SAMPLE_RATE,
			beforeBreadcrumb: (response) => {
				ignoreError =
					response &&
					response.category === 'xhr' &&
					[401, 403, 404].includes(response.data?.status_code)
				return null
			},
			beforeSend(event) {
				if (
					ignoreError ||
					skipBrowsers(event.request?.headers?.['User-Agent']) ||
					skipEvent(event?.exception?.values?.[0]?.value) ||
					skipFrame(event) ||
					skipPlugins(event.request?.url)
				) {
					return null
				} else if (event.level === 'error' || event.level === 'fatal') {
					if (
						typeof window !== 'undefined' &&
						typeof document !== 'undefined' &&
						document.documentElement?.lang?.substring(0, 2) !== 'en'
					) {
						if (skipTranslation(event?.exception?.values?.[0]?.value)) {
							return null
						}
					}
					return event // Only accept crashes or Errors.
				}
				return null // Ignore all other events such as 'Warning' | 'Log' | 'Info'
			},
			ignoreErrors: [
				'Load failed',
				'TypeError: Failed to fetch',
				'TypeError: NetworkError when attempting to fetch resource.',
			],
			integrations: [reactRouterV5BrowserTracingIntegration({ history })], // V5 Integration
			tracePropagationTargets: ['localhost'],
			replaysSessionSampleRate: 0,
			replaysOnErrorSampleRate: 0,
		})
	}
}
