import { useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'

export function useAvoidParam(paramId: string): void {
	const router = useHistory()
	const { search } = useLocation()

	/* Remove param if present */
	useEffect(() => {
		const url = new URLSearchParams(search)
		if (url.has(paramId)) {
			url.delete(paramId)
			router.replace({
				search: url.toString(),
			})
		}
	}, [search])
}
