import React from 'react'
import Script from '@core/script'

/* Hooks */
import { useDataLayer } from '@helpers/hooksHelper'

/* Local Types */
interface AWProps {
	awId: string
}

const AW = ({ awId }: AWProps): React.ReactElement => {
	useDataLayer(awId)

	return (
		<Script
			async
			src={`https://www.googletagmanager.com/gtag/js?id=${awId}`}
		></Script>
	)
}

export default AW
