import { createContext, useContext } from 'react'

/* Local Types */
type AccountLazyLoadContextValues = {
	registered?: boolean
} | null
export type AccountLazyLoadContextType =
	| [
			AccountLazyLoadContextValues,
			React.Dispatch<React.SetStateAction<AccountLazyLoadContextValues>>,
	  ]
	| [null, null]

/* AccountLazyLoad Context Provider */
export const AccountLazyLoadContext = createContext<AccountLazyLoadContextType>(
	[null, null]
)

/* AccountLazyLoad Context Hook */
export const useAccountLazyLoadContext = (): AccountLazyLoadContextType => {
	return useContext(AccountLazyLoadContext)
}
