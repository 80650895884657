import React, { useEffect, useState, useRef } from 'react'

/* Local Types */
import { PictureProps, ReadyState, Sizes, SourceSets } from './picture.models'

/* SEO Components */
import Preconnect from '@components/seo/Preconnect'

/* Create ideal image url */
const getAbsoluteURL = (
	src: string,
	width: number,
	dpr: number,
	height?: number,
	crop?: boolean,
	blur?: boolean,
	fill?: string
): string => {
	const [base, query] = src.split('?')
	const params = new URLSearchParams(query)

	/* Slightly higher res on low-density screens */
	const lowResThreshold: number = 1.5

	/* Dimensions by density */
	const idealWidth: number = dpr == 1 ? width * lowResThreshold : width
	params.set('w', parseFloat(idealWidth.toFixed(2)).toString())
	if (height && height > 0) {
		const idealHeight: number = dpr == 1 ? height * lowResThreshold : height
		params.set('h', parseFloat(idealHeight.toFixed(2)).toString())
	}
	params.set('dpr', dpr == 1 ? '1' : dpr.toString())

	/* Standard Parameters */
	params.set('auto', 'format,compress')

	/* Crop to expected ratio? */
	if (crop) {
		params.set('fit', 'crop')
	}

	/* Fill with color? */
	if (fill) {
		params.set('fit', 'fill')
		params.set('fill', 'solid')
		params.set('fill-color', fill)
	}

	/* Low-quality blur placeholder? */
	if (blur) {
		params.set('blur', '200')
		params.set('q', '10')
	}

	return base.replace(/ /g, '+') + '?' + params.toString()
}

/* Create multi-density sources */
const getSource = (
	src: string,
	width: number,
	height?: number,
	crop?: boolean,
	blur?: boolean,
	fill?: string
): string => {
	const dprSources: string[] = []
	;[1, 2, 3].forEach((value) => {
		dprSources.push(
			`${getAbsoluteURL(src, width, value, height, crop, blur, fill)} ${value}x`
		)
	})
	return dprSources.join(', ')
}

const Picture = ({
	src,
	srcSet,
	alt,
	sizes,
	className,
	blur,
	square,
	crop,
	fill,
	loading = 'lazy',
	lcp,
	width,
	height,
	onReady,
	onError,
	onErrorHide,
	onClick,
	style,
	...rest
}: PictureProps): React.ReactElement => {
	/* State - blur or loading by default */
	const [status, setStatus] = useState<ReadyState>(blur ? 'blur' : 'loading')
	const [placeholder, setPlaceholder] = useState<boolean>(blur || false)

	/* Ref */
	const imgRef = useRef<HTMLImageElement>(null)

	/* Client Load Callbacks */
	const onClientLoad = (): void => {
		if (imgRef.current?.naturalWidth === 0) {
			onClientError()
		} else {
			setTimeout(() => {
				setStatus('ready')
				onReady && onReady()
			}, 50)
		}
	}
	const onClientError = (): void => {
		setStatus('error')
		if (onErrorHide) {
			setStatus('gone')
		}
		onError && onError((imgRef.current as HTMLImageElement) || undefined)
	}

	/* Swap Placeholder with full image when in view */
	useEffect(() => {
		if (blur && placeholder) {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setPlaceholder(false)
						observer.disconnect()
					}
				})
			})
			if (imgRef.current) {
				observer.observe(imgRef.current)
			}
		}
	}, [placeholder])

	/* Initial Load Check */
	useEffect(() => {
		if (imgRef.current?.complete) {
			onClientLoad()
		}
	}, [])

	/* Image Change Check */
	useEffect(() => {
		if (lcp) {
			if (imgRef.current?.complete) {
				onClientLoad()
			} else {
				setStatus('changing')
			}
		}
	}, [src])

	/* Sourceset Widths */
	const viewportsWidths: Sizes = {
		xs: 320,
		seo: 416,
		sm: 640,
		md: 768,
		lg: 1024,
		xl: 1280,
		xxl: 1546,
	}

	/* Add missing sizes & sources */
	const useSizes: Sizes = {}
	const useSrcSets: SourceSets = {}
	const viewportKeys: string[] = Object.keys(viewportsWidths)
	viewportKeys.forEach((key, i) => {
		useSizes[key] =
			sizes?.[key] || (i == 0 ? 100 : useSizes[viewportKeys[i - 1]])
		useSrcSets[key] =
			srcSet?.[key] || (i == 0 ? src : useSrcSets[viewportKeys[i - 1]])
	})

	/* Get ideal dimensions for viewport width */
	const getSizeParams = (
		vw: string,
		dvw: number | number[]
	): [number, number, number, number] => {
		const useMedia = viewportsWidths[vw] as number
		const nextKey =
			vw !== 'xxl' ? viewportKeys[viewportKeys.indexOf(vw) + 1] : vw
		const nextMedia = viewportsWidths[nextKey] as number
		const useDvw: number = Array.isArray(dvw) ? 1 : dvw / 100
		const useWidth: number = Array.isArray(dvw) ? dvw[0] : nextMedia * useDvw
		const useHeight: number = square
			? useWidth
			: Array.isArray(dvw)
			  ? dvw[1]
			  : 0
		return [useWidth, useHeight, useMedia, nextMedia]
	}

	/* Get ideal media query for viewport width */
	const getMediaQuery = (vw: string, from: number, to: number) => {
		switch (vw) {
			case 'xs':
				return `(max-width: ${to - 0.02}px)`
			case 'xxl':
				return `(min-width: ${from}px)`
			default:
				return `(min-width: ${from}px) and (max-width: ${to - 0.02}px)`
		}
	}

	/* Largest Size for default image */
	const [lastKey, lastDVW] = Object.entries(useSizes)[0] as [
		string,
		number | number[],
	]
	const [useLastWidth, useLastHeight] = getSizeParams(lastKey, lastDVW)

	/* Class Names */
	const classNames: string = [className || '', 'img-' + status].join(' ').trim()

	/* Preload LCP Element */
	const getPreConnectDomain = (src: string): string => {
		return src && typeof src === 'string' && src.indexOf('/') > -1
			? src.split('/')[2]
			: ''
	}
	const getLinkPreload = () => {
		if (lcp) {
			return (
				<>
					<Preconnect singleSource={getPreConnectDomain(src)} />
					{Object.entries(useSizes)
						.map(([key, dvw]) => {
							const [useWidth, useHeight, useMedia, nextMedia] = getSizeParams(
								key,
								dvw
							)
							const useSrc = useSrcSets[key] || src
							if (useSrc) {
								return (
									<link
										key={key}
										rel={`preload`}
										as={`image`}
										href={getAbsoluteURL(
											useSrc,
											useWidth,
											1,
											useHeight,
											crop,
											blur,
											fill
										)}
										media={getMediaQuery(key, useMedia, nextMedia)}
										imageSrcSet={getSource(
											useSrc,
											useWidth,
											useHeight,
											crop,
											blur,
											fill
										)}
									/>
								)
							}
						})
						.filter(Boolean)}
				</>
			)
		} else {
			return null
		}
	}

	return (
		<>
			{getLinkPreload()}
			<picture>
				{!placeholder &&
					Object.entries(useSizes)
						.map(([key, dvw]) => {
							const [useWidth, useHeight, useMedia, nextMedia] = getSizeParams(
								key,
								dvw
							)
							const useSrc = useSrcSets[key] || src
							if (useSrc) {
								return (
									<source
										key={key}
										media={getMediaQuery(key, useMedia, nextMedia)}
										srcSet={getSource(
											useSrc,
											useWidth,
											useHeight,
											crop,
											false,
											fill
										)}
									/>
								)
							}
						})
						.filter(Boolean)}
				{status === 'gone' ? (
					onErrorHide || null
				) : (
					<img
						/* Image Source */
						ref={imgRef}
						src={getAbsoluteURL(
							src,
							useLastWidth,
							1,
							useLastHeight,
							crop,
							placeholder
						)}
						/* Event Handlers */
						onLoad={status !== 'error' ? onClientLoad : undefined}
						onError={onClientError}
						onClick={onClick}
						/* Attributes */
						className={classNames}
						loading={lcp ? 'eager' : loading || 'lazy'}
						fetchpriority={lcp ? 'high' : undefined}
						alt={alt || ''}
						style={style}
						/* Dimensions */
						width={width}
						height={height}
						/* Data attributes, etc. */
						data-status={status}
						{...rest}
					/>
				)}
			</picture>
		</>
	)
}

export default Picture
