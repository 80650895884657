import { useSwipeable } from 'react-swipeable'

export const useBannerSwipeable = (
	onSwipeLeft: () => void,
	onSwipeRight: () => void
) => {
	const handlers = useSwipeable({
		onSwipedLeft: () => onSwipeLeft(),
		onSwipedRight: () => onSwipeRight(),
		...{
			delta: 10, // min distance(px) before a swipe starts. *See Notes*
			preventScrollOnSwipe: false, // prevents scroll during swipe (*See Details*)
			trackTouch: true, // track touch input
			trackMouse: false, // track mouse input
			rotationAngle: 0, // set a rotation angle
			swipeDuration: Infinity, // allowable duration of a swipe (ms). *See Notes*
			touchEventOptions: { passive: true }, // options for touch listeners (*See Details*)
		},
	})

	return handlers
}
