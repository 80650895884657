import React from 'react'

/* Components */
import VWOServer from './VWOServer'
import VWOClient from './VWOClient'

const VWO = ({
	vwoId,
	vwoAlwaysOn,
}: {
	vwoId: string
	vwoAlwaysOn: boolean
}): React.ReactElement => {
	return (
		<>
			{vwoId && (
				<>
					<VWOServer vwoId={vwoId} />
					<VWOClient vwoAlwaysOn={vwoAlwaysOn} />
				</>
			)}
		</>
	)
}

export default VWO
