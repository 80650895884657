type StylesObjType = {
	readonly [key: string]: string
}

/**
 *  Convert an array of classes to a string of classes based on a CSS module object
 */
export const generateCSSClassString = (
	classStrings: string[],
	StylesObj: StylesObjType
) => {
	return (
		classStrings
			.filter((newClass) => newClass !== '')
			.map((newClass) => StylesObj[newClass])
			.reduce((accumulator, currentStr) => {
				return `${accumulator || ''} ${currentStr || ''}`
					.toString()
					.trim()
					.replace(/undefined/g, '')
			}) || ''
	)
}

/**
 * Combine sets of class names into a single string
 */
export const appendClassStrings = (...classStrings: string[]) => {
	return (
		classStrings.reduce((accumulator, currentStr) => {
			return `${accumulator || ''} ${currentStr || ''}`
				.toString()
				.trim()
				.replace(/undefined/g, '')
		}) || ''
	)
}
