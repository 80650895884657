import React from 'react'
import Script from '@core/script'

/* reCAPTCHA */
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Components */
import '../../../../lib/scss/third-party/grecaptcha.scss'

const ReCAPTCHA = (): React.ReactElement => {
	const isHuman = useDetectHuman()

	return (
		<>
			{isHuman && (
				<Script
					id="reCAPTCHA"
					src={`https://www.google.com/recaptcha/api.js?render=${getConfig().app
						?.reCaptcha?.publicKey}`}
					async
				/>
			)}
		</>
	)
}

export default ReCAPTCHA
