import React from 'react'

/* Raw Third-Party JavaScript */
/*
const VWOScript = `window._vwo_code = window._vwo_code || (function() {
    var account_id = {{id}},
        settings_tolerance = 2000,
        library_tolerance = 2500,
        use_existing_jquery = false,
        is_spa = 1,
        hide_element = 'body-disabled';
    f = false, d = document, code = {
        use_existing_jquery: function() {
            return use_existing_jquery;
        },
        library_tolerance: function() {
            return library_tolerance;
        },
        finish: function() {
            if (!f) {
                f = true;
                var a = d.getElementById('_vis_opt_path_hides');
                if (a) a.parentNode.removeChild(a);
            }
        },
        finished: function() {
            return f;
        },
        load: function(a) {
            var b = d.createElement('script');
            b.src = a;
            b.type = 'text/javascript';
            b.innerText;
            b.onerror = function() {
                _vwo_code.finish();
            };
            d.getElementsByTagName('head')[0].appendChild(b);
        },
        init: function() {
            window.settings_timer = setTimeout('_vwo_code.finish()', settings_tolerance);
            var a = d.createElement('style'),
                b = hide_element ? hide_element + '{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}' : '',
                h = d.getElementsByTagName('head')[0];
            a.setAttribute('id', '_vis_opt_path_hides');
            a.setAttribute('type', 'text/css');
            if (a.styleSheet) a.styleSheet.cssText = b;
            else a.appendChild(d.createTextNode(b));
            h.appendChild(a);
            this.load('https://dev.visualwebsiteoptimizer.com/j.php?a=' + account_id + '&u=' + encodeURIComponent(d.URL) + '&f=' + (+is_spa) + '&r=' + Math.random());
            return settings_timer;
        }
    };
    window._vwo_settings_timer = code.init();
    return code;
}());`;
*/

/* Minified Third-Party JavaScript */
const VWOScript = `window._vwo_code=window._vwo_code||function(){var e="body-disabled";return f=!1,d=document,code={use_existing_jquery:function(){return false},library_tolerance:function(){return 2500},finish:function(){if(!f){f=!0;var e=d.getElementById("_vis_opt_path_hides");e&&e.parentNode.removeChild(e)}},finished:function(){return f},load:function(e){var t=d.createElement("script");t.src=e,t.type="text/javascript",t.innerText,t.onerror=function(){_vwo_code.finish()},d.getElementsByTagName("head")[0].appendChild(t)},init:function(){window.settings_timer=setTimeout("_vwo_code.finish()",2e3);var t=d.createElement("style"),i=e?e+"{opacity:0 !important;filter:alpha(opacity=0) !important;background:none !important;}":"",n=d.getElementsByTagName("head")[0];return t.setAttribute("id","_vis_opt_path_hides"),t.setAttribute("type","text/css"),t.styleSheet?t.styleSheet.cssText=i:t.appendChild(d.createTextNode(i)),n.appendChild(t),this.load("https://dev.visualwebsiteoptimizer.com/j.php?a={{id}}&u="+encodeURIComponent(d.URL)+"&f=1&r="+Math.random()),settings_timer}},window._vwo_settings_timer=code.init(),code}();`

const VWOServer = ({ vwoId }: { vwoId: string }): React.ReactElement | null => {
	return vwoId ? (
		<script
			type="javascript/blocked"
			data-source="VWO"
			dangerouslySetInnerHTML={{
				__html: VWOScript.replace('{{id}}', vwoId),
			}}
		/>
	) : null
}

export default VWOServer
