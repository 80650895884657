import React, { FC } from 'react'

/* Local Types */
interface AudioEyeServerProps {
	config: {
		hash: string
	}
}

const AudioEyeServer: FC<AudioEyeServerProps> = ({ config }) => {
	return (
		<>
			<script
				dangerouslySetInnerHTML={{
					__html: `window.__AudioEyeSiteHash = "${config.hash}"`,
				}}
			/>
		</>
	)
}

export default AudioEyeServer
