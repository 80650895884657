import React, { useState } from 'react'

/* Components */
import { BannerPictureProps } from '../common/BannerPicture'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { getImgixURLAtHeight } from '@helpers/imgixHelper'
import { generateCSSClassString } from '@helpers/cssHelpers/index'

/* Styles */
import StoresBannerStyles from './StoresBanner.module.scss'

/* Local Types */
interface StoresSlideLinkCTA {
	text: string
	href: string
	type: 'primary' | 'secondary'
}

export interface StoresSlideDetails {
	details: StoresSlideDetailsItem[]
}

export interface StoresSlideDetailsItem {
	detailName: string
	detailItems: string[]
}

interface StoresSlide {
	id: number
	picture: BannerPictureProps
	storeName: string // e.g. Ardmore, PA
	storeTitle: string // e.g. The 1916 Company | Ardmore
	linkCtas: StoresSlideLinkCTA[]
	details: StoresSlideDetails
}

const StoresBanner = ({ slides }: { slides: StoresSlide[] }) => {
	const [activeIndex, setActiveIndex] = useState(0)

	/* Local Callbacks */
	const goToSlide = (index: number) => {
		setActiveIndex(index)
	}

	/* Local Components */
	const renderLinkCTA = (linkCta: StoresSlideLinkCTA) => {
		return (
			<SwitchLink
				key={linkCta.text}
				href={linkCta.href}
				target="_blank"
				className={`${generateCSSClassString(
					[
						'cta-button',
						linkCta.type === 'secondary'
							? 'cta-button--secondary'
							: 'cta-button--primary',
					],
					StoresBannerStyles
				)}`}
			>
				{linkCta.text}
			</SwitchLink>
		)
	}

	return (
		<div>
			<div className={`${StoresBannerStyles['desktop-container']}`}>
				<div className={`${StoresBannerStyles['desktop-items-container']}`}>
					<div className={`${StoresBannerStyles['desktop-items']}`}>
						{slides.map((slide, index) => (
							<div key={slide.id}>
								<button
									className={`${generateCSSClassString(
										[
											'desktop-items__button',
											index === activeIndex
												? 'desktop-items__button--is-active'
												: '',
										],
										StoresBannerStyles
									)}`}
									onClick={() => goToSlide(index)}
								>
									<h1
										className={`${StoresBannerStyles['desktop-items__button__text']}`}
									>
										{slide.storeName}
									</h1>
								</button>
							</div>
						))}
					</div>
				</div>
				<div className={`${StoresBannerStyles['desktop-banner-container']}`}>
					{slides.map((slide, index) => (
						<div
							key={slide.id}
							className={`${generateCSSClassString(
								[
									'desktop-banner',
									index === activeIndex ? 'desktop-banner--show' : '',
								],
								StoresBannerStyles
							)}`}
						>
							<div className={`${StoresBannerStyles['desktop-banner-items']}`}>
								<div
									className={`${StoresBannerStyles['desktop-banner-items__main']}`}
								>
									<div
										className={`${StoresBannerStyles['desktop-banner-items__main__header']}`}
									>
										<h2
											className={`${StoresBannerStyles['desktop-banner-items__main__header__title']}`}
										>
											{slide.storeTitle}
										</h2>
										<div
											className={`${StoresBannerStyles['desktop-banner-items__main__header__buttons']}`}
										>
											{slide.linkCtas.map((linkCta) => renderLinkCTA(linkCta))}
										</div>
									</div>
									<div
										className={`${StoresBannerStyles['desktop-banner-items__main__details']}`}
									>
										{slide.details.details.map((detail) => (
											<div
												key={detail.detailName}
												className={`${StoresBannerStyles['desktop-banner-items__main__details__detail']}`}
											>
												<h3
													className={`${StoresBannerStyles['desktop-banner-items__main__details__detail__title']}`}
												>
													{detail.detailName}
												</h3>
												<ul
													className={`${StoresBannerStyles['desktop-banner-items__main__details__detail__list']}`}
												>
													{detail.detailItems.map((item) => (
														<li
															key={item}
															className={`${StoresBannerStyles['desktop-banner-items__main__details__detail__list__item']}`}
														>
															{item}
														</li>
													))}
												</ul>
											</div>
										))}
									</div>
								</div>

								<div>
									<img
										src={getImgixURLAtHeight(
											slide.picture.pictureDesktop.src,
											294
										)}
										alt={slide.picture.alt}
										height={slide.picture.pictureDesktop.height}
										width={slide.picture.pictureDesktop.width}
										loading={'lazy'}
										style={{
											objectFit: 'contain',
											width: 'auto',
											height: '294px',
										}}
									/>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className={`${StoresBannerStyles['mobile-container']}`}>
				<div className={`${StoresBannerStyles['mobile-items-container']}`}>
					<div className={`${StoresBannerStyles['mobile-items']}`}>
						{slides.map((slide, index) => (
							<div
								key={slide.id}
								className={`${StoresBannerStyles['mobile-items__button-wrapper']}`}
							>
								<button
									className={`${generateCSSClassString(
										[
											'mobile-items__button',
											index === activeIndex
												? 'mobile-items__button--is-active'
												: '',
										],
										StoresBannerStyles
									)}`}
									onClick={() => goToSlide(index)}
								>
									<h1
										className={`${generateCSSClassString(
											[
												'mobile-items__button__text',
												index === activeIndex
													? 'mobile-items__button__text--is-active'
													: '',
											],
											StoresBannerStyles
										)}`}
									>
										{slide.storeName}
									</h1>
								</button>
							</div>
						))}
					</div>
				</div>
				<div className={`${StoresBannerStyles['mobile-banner-container']}`}>
					{slides.map((slide, index) => (
						<div
							key={slide.id}
							className={`${generateCSSClassString(
								[
									'mobile-banner',
									index === activeIndex ? 'mobile-banner--show' : '',
								],
								StoresBannerStyles
							)}`}
						>
							<div
								className={`${StoresBannerStyles['mobile-banner-fixed-container']}`}
							>
								<div className={`${StoresBannerStyles['mobile-banner-items']}`}>
									<div
										className={`${StoresBannerStyles['mobile-banner-items__header']}`}
									>
										<h2
											className={`${StoresBannerStyles['mobile-banner-items__header__text']}`}
										>
											{slide.storeTitle}
										</h2>
										<div
											className={`${StoresBannerStyles['mobile-banner-items__header__buttons']}`}
										>
											{slide.linkCtas.map((linkCta) => renderLinkCTA(linkCta))}
										</div>
									</div>
									<div
										className={`${StoresBannerStyles['mobile-banner-items__details']}`}
									>
										{slide.details.details.map((detail) => (
											<div
												key={detail.detailName}
												className={`${StoresBannerStyles['mobile-banner-items__details__detail']}`}
											>
												<h3
													className={`${StoresBannerStyles['mobile-banner-items__details__detail__title']}`}
												>
													{detail.detailName}
												</h3>
												<ul
													className={`${StoresBannerStyles['mobile-banner-items__details__detail__list']}`}
												>
													{detail.detailItems.map((item) => (
														<li
															key={item}
															className={`${StoresBannerStyles['mobile-banner-items__details__detail__list__item']}`}
														>
															{item}
														</li>
													))}
												</ul>
											</div>
										))}
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default StoresBanner
