import React from 'react'
import Picture from '@core/picture'

/* Helpers */
import { getImgixURL } from '@helpers/imgixHelper'

/* Styles */
import BannerPictureStyles from './BannerPicture.module.scss'

/* Local Types */
export interface BannerPicturePicture {
	width: number
	height: number
	src: string
}

/* Props */
export interface BannerPictureProps {
	pictureDesktop: BannerPicturePicture
	pictureMobile: BannerPicturePicture
	alt: string
	isLCP?: boolean
}

const BannerPicture = ({
	pictureDesktop,
	pictureMobile,
	alt,
	isLCP,
}: BannerPictureProps): React.ReactElement => {
	/* Fallback Urls */
	const desktopSrc: string = getImgixURL(pictureDesktop.src)
	const mobileSrc: string = getImgixURL(pictureMobile.src)

	const getStyles = () => {
		return (
			<style>{`.banner-picture {width: 100%; height: 100%; object-fit: cover; }
@media (min-width: 564px) and (max-width: 1023.98px) { .banner-picture { object-position: 100% 33.3333%; } }
@media (min-width: 1024px) { .banner-picture { object-position: 33.3333% 33.3333%; } }`}</style>
		)
	}

	return (
		<>
			{isLCP && getStyles()}
			<Picture
				src={mobileSrc}
				srcSet={{
					xs: mobileSrc,
					lg: desktopSrc,
				}}
				lcp={isLCP}
				alt={alt}
				className={isLCP ? `banner-picture` : BannerPictureStyles['img']}
				width={1280}
				height={664}
				sizes={{
					xs: 100,
				}}
			/>
		</>
	)
}

export default BannerPicture
