import { useEffect } from 'react'
import { usePathname } from '@next/navigation'

/* Hooks */
import { useStateChangeAttempt } from './useStateChangeAttempt'

/* Helpers */
import { getCookie, setCookie } from '@helpers/cookieHelper'

/* Increment a cookie value by 1 each pathname change */
export const useIncrementCookie = (key: string, max?: number): void => {
	const pathname = usePathname()

	useEffect(() => {
		const currentCount = parseInt(getCookie(key) || '0') || 0
		if (max && currentCount >= max) {
			return
		}

		setCookie(key, (currentCount + 1).toString())
	}, [pathname])
}

/* Check for existence of a cookie (optionally with a specific value) */
export const useHasCookie = (
	key: string,
	val?: string | number,
	everyPage?: boolean,
	isCounter?: boolean
): boolean => {
	const [match, setMatch] = useStateChangeAttempt(false)

	/* Dependencies */
	const cookieDeps: string[] = []
	if (typeof window !== 'undefined' && everyPage) {
		cookieDeps.push(window.location.pathname)
	}

	useEffect(() => {
		const cookieVal = getCookie(key)

		if (isCounter) {
			/* Counter is higher than max value? */
			if (typeof val === 'number' && parseInt(cookieVal) >= val) {
				setMatch(true)
			}
		} else {
			if (val) {
				/* Cookie exists with value? */
				if (cookieVal === val) {
					setMatch(true)
				}
			} else if (cookieVal?.trim().length > 0) {
				/* Cookie exists with any value? */
				setMatch(true)
			}
		}
	}, cookieDeps)

	return match as boolean
}
