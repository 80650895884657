import { useEffect, useState } from 'react'

/* Helpers */
import { fetchSafely } from '@helpers/fetchHelper'
import { getSFRAEndpoint } from '@helpers/sfraHelper'

/* Hooks */
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Local Types */
type BasketDataType = object | null

export const useBasket = (): [
	BasketDataType,
	React.Dispatch<React.SetStateAction<BasketDataType>>,
	() => void,
] => {
	const [basket, setBasket] = useState<BasketDataType>(null)
	const isHuman = useDetectHuman()

	const fetchBasket = async () => {
		const data = await fetchSafely(getSFRAEndpoint('Headless-Bag'))
		if (data) {
			setBasket(data)
		}
	}

	useEffect(() => {
		isHuman && fetchBasket()
	}, [isHuman])

	const refreshBasket = () => {
		fetchBasket()
	}

	return [basket, setBasket, refreshBasket]
}

export const updateBasket = async (
	pid: string,
	qty: number = 1
): Promise<BasketDataType | false> => {
	const params = {
		pid,
		qty: (qty ?? 1).toString(),
	}

	return await fetchSafely(getSFRAEndpoint('Headless-Bag', undefined, params))
}
