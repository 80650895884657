import { getAppOrigin } from '@salesforce/pwa-kit-react-sdk/utils/url'

/* Get SFRA Controller Endpoint URL */
export const getSFRAEndpoint = (
	controller: string,
	locale?: string,
	vars?: { [key: string]: string }
) => {
	locale = (locale || 'en_US').replace('-', '_')
	let ret: string =
		getAppOrigin() +
		'/mobify/proxy/ocapi/on/demandware.store/Sites-ns-company-Site/' +
		locale +
		'/' +
		controller
	if (vars) {
		ret +=
			'?' +
			Object.keys(vars)
				.map((key) => key + '=' + vars[key])
				.join('&')
	}
	return ret
}
