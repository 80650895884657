import React, { useMemo } from 'react'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { replaceEscapedNewlines } from '@helpers/strapiHelper'

/* Styles */
import TextBannerStyles from './TextBanner.module.scss'

/* Local Types */
interface TextBannerProps {
	text: string
	links?: TextLink[]
	mobiletext?: string | null
}

interface TextLink {
	text: string
	href: string
}

const TextBanner = ({
	text,
	links,
	mobiletext,
}: TextBannerProps): React.ReactElement => {
	/* Local Callbacks */
	const convertedText = useMemo(() => {
		const textWithWhitespace = replaceEscapedNewlines(text)
		const mobileTextWithWhitespace = mobiletext
			? replaceEscapedNewlines(mobiletext)
			: ''

		if (!links || links.length === 0) {
			if (mobileTextWithWhitespace) {
				return (
					<>
						<span className={TextBannerStyles['desktop']}>
							{textWithWhitespace}
						</span>
						<span className={TextBannerStyles['mobile']}>
							{mobileTextWithWhitespace}
						</span>
					</>
				)
			}

			return textWithWhitespace
		}

		const words = textWithWhitespace.match(/[\w'-]+|[.,!?]+|\s+/g) || []

		let phrase = ''
		let link = null
		let matchedLink: TextLink | null = null
		let matchedLength = 0

		const newText: (React.ReactElement | string)[] = []
		for (let i = 0; i < words.length; i++) {
			matchedLink = null
			matchedLength = 0
			for (let j = i; j < words.length && !matchedLink; j++) {
				phrase = words
					.slice(i, j + 1)
					.join('')
					.replace(/[.,!?]$/, '')
				link = links.find((link) => link.text === phrase)
				if (link) {
					matchedLink = link
					matchedLength = j - i + 1
				}
			}
			if (matchedLink) {
				newText.push(
					<SwitchLink
						key={i}
						className={TextBannerStyles['link']}
						href={matchedLink.href}
					>
						{`${matchedLink.text}`}
					</SwitchLink>
				)
				i += matchedLength - 1 // Skip the words that were part of the link
			} else {
				newText.push(words[i])
			}
		}

		return newText
	}, [text, links, mobiletext])

	return <h1 className={TextBannerStyles['container']}>{convertedText}</h1>
}

export default TextBanner
