import { Request } from 'express'

/* Configs */
import supportedCountriesConfig from '../../config/supportedCountries.json'
const { supportedCountries } =
	supportedCountriesConfig as SupportedCountriesConfig
import supportedCurrenciesConfig from '../../config/supportedCurrencies.json'
const { supportedCurrencies } =
	supportedCurrenciesConfig as SupportedCurrenciesConfig
import supportedLanguagesConfig from '../../config/supportedLanguages.json'
const { supportedLanguages } =
	supportedLanguagesConfig as SupportedLanguagesConfig
import supportedStatesConfig from '../../config/supportedStates.json'
const { supportedStates } = supportedStatesConfig as SupportedStatesConfig

/* Config Types */
export interface SupportedCountriesConfig {
	supportedCountries: SupportedCountries[]
}
export interface SupportedLanguagesConfig {
	supportedLanguages: SupportedLanguages[]
}
export interface SupportedCurrenciesConfig {
	supportedCurrencies: SupportedCurrencies[]
}
export interface SupportedStatesConfig {
	supportedStates: SupportedStates[]
}

/* Local Types */
export interface SupportedCountries {
	iso: string
	currency: string
	language: string
	name: string
	defaultLocale?: string
}
export interface SupportedCurrencies {
	key: string
	name: string
	detail?: string
	symbol?: string
	text?: string
}
export interface SupportedLanguages {
	language: string
	name: string
}
interface SupportedStates {
	iso: string
	name: string
	code: string
}
export interface GeoFromHeadersType {
	iso: string
	latitude: string
	longitude: string
	regionCode: string
	postalCode: string
	[key: string]: string
}
export interface GeoDataType {
	[key: string]: string
}

/* Local Helpers */
const determineIpFromHeaders = (req: Request): string => {
	let ip: string = ''
	if (req) {
		try {
			ip = (req.headers?.['x-source-ip'] ||
				req.headers?.['x-real-ip'] ||
				req.headers?.['x-forwarded-for'] ||
				req.ip ||
				req.socket?.remoteAddress ||
				'') as string
			if (ip?.indexOf(',') > -1) {
				ip = ip.split(',')[0]?.trim() || ''
			}
		} catch (e) {
			ip = ''
		}
	}
	return ip
}
export const determineGeoFromHeaders = (req: Request): GeoFromHeadersType => {
	const findHeaders = {
		iso: 'x-ip-src-country',
		latitude: 'x-ip-src-latitude',
		longitude: 'x-ip-src-longitude',
		regionCode: 'x-ip-src-regioncode',
		postalCode: 'x-ip-src-postalcode',

		/* Problematic headers disabled */
		// country: 'x-ip-src-countryname',
		// city: 'x-ip-src-city',
		// region: 'x-ip-src-region',
	}

	return Object.entries(findHeaders).reduce((acc, [key, value]) => {
		acc[key] = (req?.headers?.[value] || '').toString()
		return acc
	}, {} as GeoFromHeadersType)
}

/* Named Exports */
export const getSupportedCountries = (): SupportedCountries[] => {
	return supportedCountries
}
export const getSupportedCurrencies = (): SupportedCurrencies[] => {
	return supportedCurrencies
}
export const getSupportedLanguages = (): SupportedLanguages[] => {
	return supportedLanguages
}
export const getSupportedStates = (): SupportedStates[] => {
	return supportedStates
}
export const getIp = (req: Request): string => {
	return determineIpFromHeaders(req)
}
export const getGeo = (req: Request): GeoDataType => {
	return determineGeoFromHeaders(req)
}
