import { createContext, useContext, useEffect } from 'react'

/* Hooks */
import { useScrollTopTo } from '../hooks/reducedMotionHook'

/* Local Types */
type ScrollContextValues = {
	key: string | undefined
	offset: number | null
}
export type ScrollContextType =
	| [
			ScrollContextValues,
			React.Dispatch<React.SetStateAction<ScrollContextValues>>,
	  ]
	| null

/* Scroll Context Provider */
export const ScrollContext = createContext<ScrollContextType | null>(null)

/* Scroll Context Hook */
export const useScrollContext = (
	enableReset?: boolean,
	pageKey?: string
): ScrollContextType => {
	const ret = useContext(ScrollContext)
	const scrollContext = ret ? ret[0] : null
	const setScrollContext = ret ? ret[1] : null
	const { key, offset } = scrollContext ?? {}

	/* Save Scroll on Re-render and Unmount */
	useEffect(() => {
		const saveScroll = () => {
			if (setScrollContext) {
				const values: ScrollContextValues = {
					key: pageKey,
					offset: window.scrollY,
				}
				if (offset !== values.offset || key !== values.key) {
					setScrollContext(values)
				}
			}
		}
		saveScroll()
		return () => {
			saveScroll()
		}
	}, [])

	/* Reset Scroll on Render */
	useEffect(() => {
		if (enableReset && key && offset && key === pageKey) {
			useScrollTopTo(offset, 'instant')
		}
	})

	return ret
}
