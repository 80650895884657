import React, { createContext, useEffect, useState } from 'react'
import { usePathname } from '@next/navigation'

/* Hooks */
import { bodyIsUnstuck, bodyIsStuck } from '@lib/effects/bodyStuckEffect'
import { useIsLargeScreen } from '@helpers/hooksHelper'

/* Provider Types */
interface SiteHeaderContextType {
	isMobileNavOpen: boolean
	setIsMobileNavOpen: React.Dispatch<React.SetStateAction<boolean>>
	shouldFocusSearch: boolean
	setShouldFocusSearch: React.Dispatch<React.SetStateAction<boolean>>
	showSearch: boolean
	setShowSearch: React.Dispatch<React.SetStateAction<boolean>>
}

/* Context */
export const SiteHeaderContext = createContext<SiteHeaderContextType>(
	{} as SiteHeaderContextType
)

const SiteHeaderProvider = ({ children }: { children: React.ReactNode }) => {
	const pathname = usePathname()

	/* Menu State */
	const [isMobileNavOpen, setIsMobileNavOpen] = useState<boolean>(false)
	const [shouldFocusSearch, setShouldFocusSearch] = useState<boolean>(false)
	const [showSearch, setShowSearch] = useState<boolean>(false)

	/* Container State */
	const [isDesktop, setIsDesktop] = useState<boolean>(useIsLargeScreen())
	const resizeEvent: string = 'resize'

	/* Close mobile nav on route change */
	useEffect(() => {
		setIsMobileNavOpen(false)
	}, [pathname])

	/* Check if window is desktop */
	useEffect(() => {
		const getCurrentSize = () => {
			const isDesktopNow: boolean = useIsLargeScreen()
			if (isDesktopNow !== isDesktop) {
				setIsDesktop(isDesktopNow)
				if (isDesktopNow) {
					setIsMobileNavOpen(false)
				}
			}
		}
		getCurrentSize()

		window.addEventListener(resizeEvent, getCurrentSize)
		return () => {
			window.removeEventListener(resizeEvent, getCurrentSize)
		}
	}, [])

	/* Lock body on open */
	useEffect(() => {
		if (isMobileNavOpen || showSearch) {
			bodyIsStuck()
		} else {
			bodyIsUnstuck()
		}
		return () => {
			bodyIsUnstuck()
		}
	}, [isMobileNavOpen, showSearch])

	return (
		<SiteHeaderContext.Provider
			value={{
				isMobileNavOpen,
				setIsMobileNavOpen,
				shouldFocusSearch,
				setShouldFocusSearch,
				showSearch,
				setShowSearch,
			}}
		>
			{children}
		</SiteHeaderContext.Provider>
	)
}

export default SiteHeaderProvider
