import React, { useCallback, useEffect, useRef, useState } from 'react'

/* Components */
import BannerSlider from '../common/BannerSlider/BannerSlider'
import MuteButton from './MuteButton'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { generateCSSClassString } from '@helpers/cssHelpers/index'
import { replaceEscapedNewlines } from '@helpers/strapiHelper'
import { getImgixURL } from '@helpers/imgixHelper'

/* Hooks */
import { useBannerSwipeable } from '../common/useBannerSwipeable'
import { useIsDesktop, useIsWiderThan } from '@lib/hooks/useIsDesktop'
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Styles */
import VideoBannerStyles from './VideoBanner.module.scss'

type videoSrcType =
	| {
			url: string
			mime: string
	  }
	| undefined

/* Local Types */
interface VideoBannerSlide {
	id: number
	title?: string
	linkHref?: string
	linkName?: string
	bgColor?: string
	objectFit?: string | null
	// Video sources
	desktopSrc: {
		mp4: videoSrcType
		webm: videoSrcType
	}
	mobileSrc: {
		mp4: videoSrcType
		webm: videoSrcType
	}
	// Color Stuff
	titleColor?: string
	ctaColor?: string
	sliderItemColor?: string
	showSliderItem?: boolean
	allowAudio?: boolean
}
interface VideoBannerVideRef {
	[key: string]: HTMLVideoElement | null
}

const VideoBanner = ({
	slides,
	interval = 8,
	desktopHeight = 795,
	mobileHeight = 663,
}: {
	slides: VideoBannerSlide[]
	interval?: number
	desktopHeight?: number
	mobileHeight?: number
}): React.ReactElement => {
	const isDesktop = useIsDesktop()
	const isDesktopView = useIsWiderThan(1280)
	const [activeIndex, setActiveIndex] = useState(0)
	const [areVideosMuted, setAreVideosMuted] = useState(true)
	const bannerRef = useRef(null)
	const videoRefs = useRef<VideoBannerVideRef>({})

	/* Load Video on Human Interaction */
	const isHuman = useDetectHuman()

	/* Local Callbacks */
	const nextSlide = useCallback(() => {
		setActiveIndex((currIndex) =>
			currIndex === slides.length - 1 ? 0 : currIndex + 1
		)
	}, [slides.length])
	const previousSlide = useCallback(() => {
		setActiveIndex((currIndex) =>
			currIndex === 0 ? slides.length - 1 : currIndex - 1
		)
	}, [slides.length])
	const goToSlide = (index: number) => {
		setActiveIndex(index)
	}

	/* Local Hooks */
	const handlers = useBannerSwipeable(nextSlide, previousSlide)

	/* Autoplay Effect */
	useEffect(() => {
		let autoPlayInterval: NodeJS.Timeout

		const observer = new IntersectionObserver((entries) => {
			// If the component is in view, start the interval
			if (entries[0].isIntersecting) {
				autoPlayInterval = setInterval(nextSlide, interval * 1000)
			} else {
				// If the component is not in view, clear the interval
				clearInterval(autoPlayInterval)
			}
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current)
		}

		return () => {
			observer.disconnect()
			clearInterval(autoPlayInterval)
		}
	}, [activeIndex, interval, nextSlide])

	/* Restart each video on slide change (resets when activeIndex changes) */
	useEffect(() => {
		const cur = videoRefs.current[activeIndex]
		if (cur) {
			cur.currentTime = 0
		}
	}, [activeIndex])

	/* Local Components */
	const PrimaryLink = (
		<SwitchLink
			href={slides[activeIndex].linkHref}
			style={{
				borderColor: slides[activeIndex].ctaColor || '#fff',
				color: slides[activeIndex].ctaColor || '#fff',
			}}
			className={`${VideoBannerStyles['cta-button']} ${VideoBannerStyles['cta-button__text']}`}
		>
			{slides[activeIndex].linkName}
			{slides[activeIndex].title && (
				<span className={`${VideoBannerStyles['seo']}`}>
					{' '}
					- {replaceEscapedNewlines(slides[activeIndex].title)}
				</span>
			)}
		</SwitchLink>
	)

	const DesktopItems = (
		<div className={VideoBannerStyles['desktop-items']}>
			<div className={`${VideoBannerStyles['desktop-items__cta-container']}`}>
				{slides[activeIndex].title ? (
					<div
						style={{ color: slides[activeIndex].titleColor || '#fff' }}
						className={`${VideoBannerStyles['desktop-items__cta-title']}`}
					>
						{replaceEscapedNewlines(slides[activeIndex].title || '')}
					</div>
				) : null}
				{slides[activeIndex].linkHref ? <div>{PrimaryLink}</div> : null}
			</div>
			{slides[activeIndex].showSliderItem ? (
				<BannerSlider
					slideCount={slides.length}
					activeSliderIndex={activeIndex}
					goToSlide={goToSlide}
					activeIndexColor={slides[activeIndex].sliderItemColor}
				/>
			) : null}
		</div>
	)

	const MobileItems = (
		<div className={VideoBannerStyles['mobile-items']}>
			<div>
				<div className={`${VideoBannerStyles['mobile-items__cta-flex']}`}>
					{slides[activeIndex].title ? (
						<div
							style={{ color: slides[activeIndex].titleColor || '#fff' }}
							className={`${VideoBannerStyles['mobile-items__cta-title']}`}
						>
							{replaceEscapedNewlines(slides[activeIndex].title || '')}
						</div>
					) : null}
					{slides[activeIndex].linkHref ? <div>{PrimaryLink}</div> : null}
				</div>
			</div>
			<div className={`${VideoBannerStyles['mobile-items__slider']}`}>
				{slides[activeIndex].showSliderItem ? (
					<BannerSlider
						slideCount={slides.length}
						activeSliderIndex={activeIndex}
						goToSlide={goToSlide}
					/>
				) : null}
			</div>
		</div>
	)

	/* Determine View Type for Videos */
	const [viewType, setViewType] = useStateChangeAttempt('none')
	useEffect(() => {
		setViewType(isDesktopView ? 'desktop' : 'mobile')
	}, [isDesktopView])

	return (
		<div
			ref={bannerRef}
			className={`${VideoBannerStyles['wrap']}`}
			style={
				{
					backgroundColor: slides[activeIndex].bgColor || '#fff',
					height: !isDesktop ? mobileHeight : desktopHeight,
				} as React.CSSProperties
			}
		>
			<div
				className={`${VideoBannerStyles['container']}`}
				style={
					{
						height: !isDesktop ? mobileHeight : desktopHeight,
					} as React.CSSProperties
				}
			>
				<div {...handlers}>
					{slides.map((slide, index) => {
						return (
							<div
								key={viewType + '-' + slide.id}
								className={`${generateCSSClassString(
									['video', index === activeIndex ? 'video--show' : ''],
									VideoBannerStyles
								)}`}
							>
								{viewType === 'none' || !isHuman ? (
									<span></span>
								) : (
									<video
										data-view-type={viewType}
										ref={(el) => (videoRefs.current[index] = el)}
										autoPlay
										preload="none"
										playsInline
										loop
										muted={areVideosMuted}
										className={VideoBannerStyles['video__tag']}
										style={
											{
												objectFit: slides[activeIndex].objectFit || 'cover',
											} as React.CSSProperties
										}
									>
										{Object.entries(
											slide[`${viewType}Src` as 'desktopSrc' | 'mobileSrc']
										)
											.map(([key, src]) => {
												if (src?.url) {
													return (
														<source
															key={viewType + '-' + key}
															src={getImgixURL(src.url)}
															type={src.mime}
														/>
													)
												}
											})
											.filter((x) => x)}
										{`Your browser does not support playback of this video.`}
									</video>
								)}
							</div>
						)
					})}
				</div>
				{DesktopItems}
				{MobileItems}
				{slides[activeIndex].allowAudio && (
					<div className={`${VideoBannerStyles['mute-button-container']}`}>
						<MuteButton
							isMuted={areVideosMuted}
							handleClick={() => setAreVideosMuted(!areVideosMuted)}
						/>
					</div>
				)}
			</div>
		</div>
	)
}

export default VideoBanner
