import { useEffect } from 'react'

declare global {
	interface Window {
		criteo_q: {
			account?: string
			availability?: '0' | '1'
			event: string
			item?:
				| string
				| {
						id: string
						price: string
						quantity: number
				  }[]
			price?: string
			type?: string
		}[]
	}
}

export function useCriteoEvent(
	tagId: string,
	pathname: string,
	isHuman: boolean
) {
	const getDataAttribute: (name: string) => string = (name) => {
		return (
			document.querySelector(`[data-${name}]`)?.getAttribute(`data-${name}`) ||
			''
		)
	}
	useEffect(() => {
		const deviceType = /iPad/.test(navigator.userAgent)
			? 't'
			: /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(
						navigator.userAgent
			    )
			  ? 'm'
			  : 'd'

		if (tagId && isHuman) {
			window.criteo_q = window.criteo_q || []
			if (pathname == '/') {
				window.criteo_q.push(
					{ event: 'setAccount', account: tagId },
					{ event: 'setSiteType', type: deviceType },
					/*
                    { event: 'setEmail', email: '##Email Address##', hash_method: '##Hash Method##' },
                    { event: 'setCustomerId', id: '##Customer Id##' },
                    { event: 'setRetailerVisitorId', id: '##Visitor Id##' },
                    { event: 'setZipcode', zipcode: '##Zip Code##' },
                    */
					{ event: 'viewHome' }
				)
			} else if (
				pathname.includes('/watches/') ||
				pathname.includes('/pre-owned/')
			) {
				const parts = pathname.split('/')
				if (parts.length == 5) {
					const productId = parts.pop()
					if (productId) {
						/* Unit price of the product */
						const productPrice = getDataAttribute('price') || ''

						/* Product availability status. 1 if the product is in stock and 0 if the product is out of stock */
						const productAvailability =
							getDataAttribute('availability') === 'true' ? '1' : '0'

						window.criteo_q.push(
							{ event: 'setAccount', account: tagId },
							{ event: 'setSiteType', type: deviceType },
							/*
                            { event: 'setEmail', email: '##Email Address##', hash_method: '##Hash Method##' },
                            { event: 'setCustomerId', id: '##Customer Id##' },
                            { event: 'setRetailerVisitorId', id: '##Visitor Id##' },
                            { event: 'setZipcode', zipcode: '##Zip Code##' },
                            */
							{
								event: 'viewItem',
								item: productId,
								price: productPrice,
								availability: productAvailability,
							}
						)
						window.criteo_q.push(
							{ event: 'setAccount', account: tagId },
							{ event: 'setSiteType', type: deviceType },
							{ event: 'viewItem', item: productId }
						)
					}
				}
			}
		}

		/* Track add to cart event */
		const addToBag = (e: Event) => {
			const { detail } = e as CustomEvent
			if (detail) {
				const { pid, quantity } = detail
				if (pid) {
					const productPrice = getDataAttribute('price') || ''
					const item = {
						id: pid,
						price: productPrice,
						quantity: parseInt(quantity.toString()) || 1,
					}
					window.criteo_q.push(
						{ event: 'setAccount', account: tagId },
						{ event: 'setSiteType', type: deviceType },
						/*
                        { event: 'setEmail', email: '##Email Address##', hash_method: '##Hash Method##' },
                        { event: 'setCustomerId', id: '##Customer Id##' },
                        { event: 'setRetailerVisitorId', id: '##Visitor Id##' },
                        { event: 'setZipcode', zipcode: '##Zip Code##' },
                        */
						{ event: 'addToCart', item: [item] }
					)
				}
			}
		}
		document.body?.addEventListener('bag:add', addToBag)
		return () => {
			document.body?.removeEventListener('bag:add', addToBag)
		}
	}, [tagId, pathname, isHuman])
}
