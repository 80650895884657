import React, { ReactNode } from 'react'
import { Helmet } from 'react-helmet'

/* Props */
type InlineCSSProps = {
	componentName: string
	styles: string
	children: ReactNode
}

const InlineCSS = ({
	componentName,
	styles,
	children,
}: InlineCSSProps): React.ReactElement => {
	const prefixClassNames = (children: ReactNode): ReactNode => {
		return React.Children.map(children, (child) => {
			if (React.isValidElement(child)) {
				/* Props to alter */
				const newProps: {
					children?: ReactNode
					className?: string | null
					rawclassname?: string | null
				} = {}

				/* Prefix className with componentName_ */
				if (child.props?.className) {
					/* Prefix all classNames */
					let className: string =
						(child.props.className
							.split(' ')
							.map((className: string) => {
								if (className) {
									const firstLetter: string = className.substring(0, 1)
									if (
										firstLetter === firstLetter.toUpperCase() &&
										className.indexOf('_') > -1
									) {
										return className
									}
									return `${componentName}_${className}`
								}
								return ''
							})
							.join(' ') as string) || ''

					/* Add rawclassname as non-prefixed className? */
					if (child.props.rawclassname) {
						className += ' ' + child.props.rawclassname
						newProps.rawclassname = undefined
					}

					/* Any classname? */
					newProps.className =
						className.replace(/\s\s+/g, ' ').trim() || undefined
				}

				/* Prefix className for children? */
				if (child.props?.children) {
					newProps.children = prefixClassNames(child.props.children)
				}

				return React.cloneElement(
					child,
					newProps
				) as React.PropsWithChildren<React.ReactElement>
			}

			return child
		})
	}

	return (
		<>
			<Helmet>
				<style id={`css-${componentName}`}>{styles}</style>
			</Helmet>
			{prefixClassNames(children)}
		</>
	)
}

export default InlineCSS
