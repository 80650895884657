import React from 'react'
import { Link } from 'react-router-dom'

/* Hooks */
import { useKnownSFRA } from '@lib/hooks/useKnownSFRA'

/* Local Helpers */
const isStaticFile = (href: string | undefined): boolean => {
	return href && /\.(js|css|xml|txt|pdf|doc|xls|docx|xlsx)$/.test(href)
		? true
		: false
}
const isAbsoluteUrl = (href: string | undefined): boolean => {
	return href && href.indexOf('://') > -1 ? true : false
}
const isProtocol = (href: string | undefined): boolean => {
	return href && /^[a-z]+:/.test(href) ? true : false
}

/* Local Types */
interface SwitchLinkProps {
	href: string | undefined
	className?: string
	children: React.ReactNode
	target?: string
	onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void
	dataAttributes?: DataAttributes
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	[key: string]: any
}
interface DataAttributes {
	[key: string]: string
}

const SwitchLink = (props: SwitchLinkProps): React.ReactElement => {
	const {
		href,
		className,
		children,
		target,
		dataAttributes,
		onClick,
		...rest
	} = props

	/* Add Data Attributes? */
	const addDataAttributes = (): DataAttributes => {
		if (dataAttributes) {
			return Object.keys(dataAttributes).reduce(
				(acc: { [key: string]: string }, key: string) => {
					const val: string = dataAttributes[key]?.toString() || ''
					if (val) {
						acc[`data-${key}`] = val.toString()
					}
					return acc
				},
				{}
			)
		}
		return {}
	}

	/* Scrub relative href */
	const scrubHref = (href?: string): string => {
		if (!href) {
			return ''
		}
		return href
			.replace('categories/', 'blog/')
			.replace('../../../../../', '')
			.replace(/^\/(\.\.\/{1,10})+/, '/')
	}

	/* Targeted or External or SFRA Links or Static Files should immediately escape the router */
	return target ||
		isAbsoluteUrl(href) ||
		useKnownSFRA(href) ||
		isProtocol(href) ||
		isStaticFile(href) ? (
		<a
			href={href}
			target={target || undefined}
			className={className}
			onClick={onClick || undefined}
			data-escape={`true`}
			{...rest}
			{...addDataAttributes()}
		>
			{children}
		</a>
	) : (
		<Link
			to={scrubHref(href)}
			className={className}
			onClick={onClick || undefined}
			{...rest}
			{...addDataAttributes()}
		>
			{children}
		</Link>
	)
}

export default SwitchLink
