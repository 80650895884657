import React from 'react'

/* Components */
import InlineCSS from '@components/seo/InlineCSS'

/* Helpers */
import { routeRolex } from '@helpers/routeHelper'

/* Inline Styles */
import rolexStyles from '!raw-loader!postcss-loader!sass-loader!./RolexPlaque.module.scss'

const RolexPlaque = ({ callbackHandler }: { callbackHandler?: () => void }) => {
	/* Fixed Constants */
	const iframeURL: string =
		'https://static.rolex.com/retailers/clock/?colour=gold&apiKey=366b8d2f8df94cbf8cce59f04411e3ee&lang=en_us'

	/* Variable Constants */
	const clickToURL: string = routeRolex()

	/* Client Events */
	const onKeyPress = (e: React.KeyboardEvent): void => {
		if (e.key === 'Enter' || e.key === ' ') {
			gotoRolex(false, callbackHandler)
		}
	}
	const onClick = (e: React.MouseEvent): void => {
		const newWindow: boolean =
			e && (e.shiftKey || e.ctrlKey || e.metaKey || e.altKey)
		gotoRolex(newWindow, callbackHandler)
	}

	/* Local Helpers */
	const gotoRolex = (newWindow: boolean, callback?: () => void): void => {
		if (newWindow) {
			window.open(clickToURL, '_blank')
		} else {
			window.location.href = clickToURL
		}

		callback?.()
	}

	return (
		<InlineCSS styles={rolexStyles} componentName={'RolexPlaque'}>
			<button
				className={'plaque'}
				onClick={onClick}
				onKeyDown={onKeyPress}
				onKeyUp={onKeyPress}
				aria-label={`Rolex`}
			>
				<div className={'plaque__wrap'}></div>
				{/* Legacy support for scrolling="no" and frameborder="no" */}
				<iframe
					className={'plaque__iframe'}
					scrolling="no"
					frameBorder={`no`}
					id={`rolex_retailer`}
					src={iframeURL}
					title={`Rolex`}
				></iframe>
			</button>
		</InlineCSS>
	)
}

export default RolexPlaque
