import { useState, useEffect } from 'react'

/* For debugging on localhost only */
const DEBUG_STATE_CHANGE: boolean = false

/* Local Types */
type stateChangeType =
	| string
	| number
	| boolean
	| null
	| object
	| NodeJS.Timeout
export type stateChangeFunction = (newValue: stateChangeType) => boolean

/* Shared Types */
export type StringState = [string, React.Dispatch<React.SetStateAction<string>>]
export type NumberState = [number, React.Dispatch<React.SetStateAction<number>>]
export type BooleanState = [
	boolean,
	React.Dispatch<React.SetStateAction<boolean>>,
]

/* Wrap useState in a changed value check to avoid unnecessary re-renders */
export const useStateChangeAttempt = (
	defaultValue?: stateChangeType,
	id?: string
): [stateChangeType, stateChangeFunction] => {
	/* Default Value */
	defaultValue = typeof defaultValue === 'undefined' ? null : defaultValue
	const [value, setValue] = useState<stateChangeType>(defaultValue)

	/* Track State Changes */
	useEffect(() => {
		if (DEBUG_STATE_CHANGE) {
			console.info(`State Updated[${id ? id : typeof value}]:`, value)
		}
	}, [value])

	/* Attempt to Set State */
	const setAttempt: stateChangeFunction = (
		newValue: stateChangeType
	): boolean => {
		const wasChanged: boolean =
			value && typeof value === 'object'
				? JSON.stringify(value) !== JSON.stringify(newValue)
				: typeof value !== typeof newValue || value !== newValue
		if (wasChanged) {
			setValue(newValue)
		}
		if (DEBUG_STATE_CHANGE) {
			console.info(
				`State[${id ? id : typeof newValue}]:`,
				[value, newValue],
				wasChanged
			)
		}
		return wasChanged
	}
	return [value, setAttempt]
}
