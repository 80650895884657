import { useState, useEffect } from 'react'

/* PWA Hooks */
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

/* Bundle ID for Versioning CSS Files */
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'
const appConfig = getConfig()
const BUNDLE_ID = appConfig?.app?.bundleId

/* Local Types */
type LinkAttributesType = {
	[key: string]: string
}

export const useNonBlockingPageCSS = (
	id: string,
	explicit?: boolean,
	skipLoad?: boolean
): boolean => {
	const [isMounted, setIsMounted] = useState<boolean>(false)

	/* Attribute for Preloading Page CSS */
	const attributePreload: string = 'data-css-id'

	useEffect(() => {
		/* Skip loading css until needed? */
		if (skipLoad) {
			return
		}

		/* Alert all components that css has loaded */
		const triggerBodyEvent = () => {
			document.body.dispatchEvent(new CustomEvent('css:ready'))
		}

		/* Remove existing page css when the id changes */
		if (!explicit) {
			const existingLink = document.querySelector(`[${attributePreload}]`)
			if (existingLink) {
				if (existingLink.getAttribute(attributePreload) === id) {
					setIsMounted(true)
					triggerBodyEvent()
					return
				} else {
					document.head.removeChild(existingLink)
				}
			}
		}

		/* Add new page css on mount? */
		if (!isMounted) {
			const linkAttributes: LinkAttributesType = explicit
				? {
						id: `css-main-${id}`,
						rel: 'stylesheet',
						href: getAssetUrl(`static/css/${id}.css`),
				  }
				: {
						id: `css-page-${id}`,
						rel: 'stylesheet',
						href: getAssetUrl(`static/css/pages-${id}.css`),
						'data-runner': BUNDLE_ID || '000',
						[attributePreload]: id,
				  }

			const link = document.createElement('link')
			Object.keys(linkAttributes).forEach((key) => {
				link.setAttribute(key, linkAttributes[key])
			})

			/* Set mounted when css file is available to display dependent components */
			link.onload = () => {
				setTimeout(() => {
					setIsMounted(true)
					triggerBodyEvent()
				}, 10)
			}

			document.head.appendChild(link)
		}
	}, [id, skipLoad])
	return isMounted
}
