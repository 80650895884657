import React from 'react'

import { generateCSSClassString } from '@helpers/cssHelpers/index'
import BannerSliderStyles from './BannerSlider.module.scss'

const BannerSlider = ({
	activeSliderIndex,
	slideCount,
	goToSlide,
	activeIndexColor = '#fff',
	activeIndexSelectedColor = '#fff',
}: {
	activeSliderIndex: number
	slideCount: number
	goToSlide: (index: number) => void
	activeIndexColor?: string
	activeIndexSelectedColor?: string
}) => {
	return (
		<div className={`${BannerSliderStyles['slide-container']}`}>
			<div className={`${BannerSliderStyles['slide-container__items']}`}>
				{Array(slideCount)
					.fill(slideCount)
					.map((_, i) => i)
					?.map((slideIndex) => (
						<div key={slideIndex}>
							<button
								onClick={() => goToSlide(slideIndex)}
								style={
									{
										//backgroundColor: activeSliderIndex === slideIndex ? color : '',
									}
								}
								className={`${generateCSSClassString(
									['slide-container__item'],
									BannerSliderStyles
								)}`}
							>
								<p
									className={`${BannerSliderStyles['slide-container__item__text']}`}
									style={{ color: activeIndexColor }}
								>{`0${slideIndex + 1}`}</p>
							</button>
							{activeSliderIndex === slideIndex ? (
								<div
									style={{ backgroundColor: activeIndexSelectedColor }}
									className={`${BannerSliderStyles['slide-container__item__border-div']}`}
								></div>
							) : null}
						</div>
					))}
			</div>
		</div>
	)
}

export default BannerSlider
