import React from 'react'
import { Helmet } from 'react-helmet'

/* Local Types */
interface PreconnectProps {
	singleSource?: string
	sources?:
		| {
				[key: string]: string
		  }
		| undefined
}

/* Output a list of <link rel='preconnect' href='...' /> */
export default function Preconnect(props: PreconnectProps): React.ReactElement {
	if (props.singleSource) {
		return (
			<Helmet>
				<link rel={'preconnect'} href={props.singleSource} />
			</Helmet>
		)
	}

	if (props.sources) {
		return (
			<Helmet>
				{Object.entries(props.sources).map(([key, value]) => {
					return <link key={key} rel={'preconnect'} href={value} />
				})}
			</Helmet>
		)
	}

	return <></>
}
