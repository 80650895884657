import { useEffect } from 'react'
import { usePathname, useQueryString } from '@next/navigation'

/* Local Hooks */
import {
	useStateChangeAttempt,
	stateChangeFunction,
} from '@lib/hooks/useStateChangeAttempt'

/* Local Constants */
const maxTime: number = 3600000 // 1 hour

export const useStalePageCheck = (): void => {
	/* Track current page */
	const pathname = usePathname()
	const queryString = useQueryString()

	/* State */
	const [timer, setTimer] = useStateChangeAttempt(null) as [
		NodeJS.Timeout,
		stateChangeFunction,
	]

	/* Reload the page after a set time */
	useEffect(() => {
		/* Reload page */
		const doReload = (): void => {
			window.location.reload()
		}

		/* Clear timer */
		const clearTimer = (): void => {
			if (timer) {
				clearTimeout(timer)
			}
		}

		/* Set timer */
		const setNewTimer = (): void => {
			clearTimer()
			setTimer(setTimeout(doReload, maxTime))
		}

		/* On render change */
		setNewTimer()
		return () => clearTimer()
	}, [pathname, queryString])
}
