import React from 'react'
import { JsonLd } from 'react-schemaorg'
import { Organization } from 'schema-dts'

const HomeSchema = () => {
	return (
		<JsonLd<Organization>
			item={{
				'@context': 'https://schema.org',
				'@type': 'Organization',
				name: 'The 1916 Company',
				url: 'https://www.the1916company.com/',
				logo: 'https://the1916company.imgix.net/1916_og_logo.png',
				description:
					'Shop, trade & sell with one of the largest collections of new & used luxury watches online, The 1916 Company.',
				contactPoint: {
					'@type': 'ContactPoint',
					telephone: '855-906-5324',
					contactType: 'customer service',
					contactOption: 'TollFree',
					availableLanguage: 'en',
				},
				sameAs: [
					'https://www.instagram.com/the1916company/',
					'https://www.youtube.com/@the1916company',
					'https://www.facebook.com/the1916company/',
				],
			}}
		/>
	)
}

export default HomeSchema
