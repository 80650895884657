/* Helpers */
import { getRemovedSFCCPrefix } from '@helpers/routeHelper'

/* Shared Types */
import { CatalogItem, CatalogItemSectionValues } from '@lib/mock/nav.models'

/* Local Types */
interface GetLinkReturn {
	target: string | undefined
	href: string
}

/* Fixed Config */
const goToPrefix: string = 'go-to-'

export const getItemLink = (item: CatalogItem) => {
	let href: string = item.href || item.key || ''
	if (href && href.indexOf('#') == -1) {
		if (!href.startsWith('/')) {
			href = '/' + href
		}
		if (!href.endsWith('/')) {
			href += '/'
		}
	}
	return href
}

export const getNavLink = (
	item: CatalogItem,
	val: CatalogItemSectionValues
): GetLinkReturn => {
	let ret: string = ''
	let target: string | undefined = undefined
	let { key, href } = val ?? {}
	key = key || ''
	href = href || ''

	if (key.indexOf(goToPrefix) > -1) {
		/* Explicit go-to- link */
		ret = '/' + key.replace(goToPrefix, '') + '/'
	} else if (href.indexOf('://') > -1 || key.indexOf('://') > -1 || false) {
		/* External link */
		ret = href || key || ''
		target = '_blank'
	} else if (href && href !== '/..' && href.indexOf('/') > -1) {
		/* Direct href */
		ret = getItemLink(val)
	} else {
		/* Calculated href */
		ret =
			'/' +
				item.key +
				'/' +
				(href
					? href + '/'
					: getRemovedSFCCPrefix(key || '', item.key || '') + '/') || ''
		ret = ret.replace('/../', '').replace('/rlx-cpo/', '/')
	}

	return {
		target: target,
		href: ret,
	}
}
