import React, { useCallback, useEffect, useRef, useState } from 'react'

/* Components */
import BannerPicture, { BannerPictureProps } from '../common/BannerPicture'
import BannerSlider from '../common/BannerSlider/BannerSlider'
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { generateCSSClassString } from '@helpers/cssHelpers/index'
import { replaceEscapedNewlines } from '@helpers/strapiHelper'

/* Hooks */
import { useBannerSwipeable } from '../common/useBannerSwipeable'

/* Styles */
import BrandsBannerStyles from './ArticlesBanner.module.scss'

/* Local Types */
interface ArticleSlide {
	id: number
	picture: BannerPictureProps
	articleCategoryTitle?: string
	articleTitle: string
	articleBody: string
	ctaTitle: string
	ctaLinkHref: string
}

const ArticlesBanner = ({
	interval = 6,
	slides,
}: {
	interval?: number
	slides: ArticleSlide[]
}) => {
	const [activeIndex, setActiveIndex] = useState(0)
	const bannerRef = useRef(null)

	/* Local Callbacks */
	const nextSlide = useCallback(() => {
		setActiveIndex((currIndex) =>
			currIndex === slides.length - 1 ? 0 : currIndex + 1
		)
	}, [slides.length])
	const previousSlide = useCallback(() => {
		setActiveIndex((currIndex) =>
			currIndex === 0 ? slides.length - 1 : currIndex - 1
		)
	}, [slides.length])
	const goToSlide = (index: number) => {
		setActiveIndex(index)
	}

	/* Local Hooks */
	const handlers = useBannerSwipeable(nextSlide, previousSlide)

	/* Autoplay Effect */
	useEffect(() => {
		let autoPlayInterval: NodeJS.Timeout

		const observer = new IntersectionObserver((entries) => {
			// If the component is in view, start the interval
			if (entries[0].isIntersecting) {
				autoPlayInterval = setInterval(nextSlide, interval * 1000)
			} else {
				// If the component is not in view, clear the interval
				clearInterval(autoPlayInterval)
			}
		})

		if (bannerRef.current) {
			observer.observe(bannerRef.current)
		}

		return () => {
			observer.disconnect()
			clearInterval(autoPlayInterval)
		}
	}, [activeIndex, interval, nextSlide])

	/* Local Components */
	const PrimaryLink = (
		<SwitchLink
			href={slides[activeIndex].ctaLinkHref}
			style={{
				borderColor: '#fff',
				color: '#fff',
			}}
			className={`${BrandsBannerStyles['cta-button']} ${BrandsBannerStyles['cta-button__text']}`}
		>
			{slides[activeIndex].ctaTitle}
			<span className={`${BrandsBannerStyles['seo']}`}>
				{' '}
				- {slides[activeIndex].articleTitle}
			</span>
		</SwitchLink>
	)

	return (
		<div ref={bannerRef}>
			<div className={`${BrandsBannerStyles['desktop-container']}`}>
				<div className={`${BrandsBannerStyles['desktop-items-container']}`}>
					<div className={`${BrandsBannerStyles['desktop-items']}`}>
						<div className={`${BrandsBannerStyles['desktop-items__content']}`}>
							{slides[activeIndex].articleCategoryTitle ? (
								<p
									className={`${BrandsBannerStyles['desktop-items__content__category-text']}`}
									aria-roledescription="subtitle"
								>
									{replaceEscapedNewlines(
										slides[activeIndex].articleCategoryTitle || ''
									)}
								</p>
							) : null}
							<h2
								className={`${BrandsBannerStyles['desktop-items__content__title-text']}`}
							>
								{replaceEscapedNewlines(slides[activeIndex].articleTitle)}
							</h2>
							<p
								className={`${BrandsBannerStyles['desktop-items__content__description-text']}`}
							>
								{replaceEscapedNewlines(slides[activeIndex].articleBody)}
							</p>
							<div>{PrimaryLink}</div>
						</div>
						<BannerSlider
							slideCount={slides.length}
							activeSliderIndex={activeIndex}
							goToSlide={goToSlide}
						/>
					</div>
				</div>
				<div className={`${BrandsBannerStyles['desktop-banner-container']}`}>
					{slides.map((slide, index) => (
						<div
							key={slide.id}
							className={`${generateCSSClassString(
								[
									'desktop-banner',
									index === activeIndex ? 'desktop-banner--show' : '',
								],
								BrandsBannerStyles
							)}`}
						>
							<BannerPicture
								pictureDesktop={slide.picture.pictureDesktop}
								pictureMobile={slide.picture.pictureMobile}
								alt={slide.picture.alt}
							/>
						</div>
					))}
				</div>
			</div>
			<div
				{...handlers}
				className={`${BrandsBannerStyles['mobile-container']}`}
			>
				<div className={`${BrandsBannerStyles['mobile-banner-container']}`}>
					{slides.map((slide, index) => (
						<div
							key={slide.id}
							className={`${generateCSSClassString(
								[
									'mobile-banner',
									index === activeIndex ? 'mobile-banner--show' : '',
								],
								BrandsBannerStyles
							)}`}
						>
							<BannerPicture
								pictureDesktop={slide.picture.pictureDesktop}
								pictureMobile={slide.picture.pictureMobile}
								alt={slide.picture.alt}
							/>
						</div>
					))}
				</div>
				<div className={`${BrandsBannerStyles['mobile-items-container']}`}>
					<div className={`${BrandsBannerStyles['mobile-items']}`}>
						<div className={`${BrandsBannerStyles['mobile-items__content']}`}>
							{slides[activeIndex].articleCategoryTitle ? (
								<h1
									className={`${BrandsBannerStyles['mobile-items__content__category-text']}`}
								>
									{replaceEscapedNewlines(
										slides[activeIndex].articleCategoryTitle || ''
									)}
								</h1>
							) : null}
							<h2
								className={`${BrandsBannerStyles['mobile-items__content__title-text']}`}
							>
								{replaceEscapedNewlines(slides[activeIndex].articleTitle)}
							</h2>
							<p
								className={`${BrandsBannerStyles['mobile-items__content__description-text']}`}
							>
								{replaceEscapedNewlines(slides[activeIndex].articleBody)}
							</p>
							<div>{PrimaryLink}</div>
						</div>
						<BannerSlider
							slideCount={slides.length}
							activeSliderIndex={activeIndex}
							goToSlide={goToSlide}
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ArticlesBanner
