import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

/* PWA Hooks */
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import { getAssetUrl } from '@salesforce/pwa-kit-react-sdk/ssr/universal/utils'

/* Sentry */
import { init, captureMessage, captureException } from '@sentry/react'

import BrandLogo from '@components/logo/BrandLogo'

const ErrorPage = (props) => {
	const { skipSentry, stack, message, resetErrorBoundary } = props

	/* Show stack trace */
	const [showStack, setShowStack] = useState(false)

	/* Sentry enabled? */
	const sentry = getConfig()?.app?.sentry || {}
	const SENTRY_DSN = sentry?.dsn || ''
	const SENTRY_ENABLED = SENTRY_DSN ? sentry?.enabled || false : false

	/* Initialize Sentry */
	const useSentry = () => {
		if (!skipSentry) {
			const SENTRY_SAMPLE_RATE = sentry?.sampleRate || 0.1
			const SENTRY_ENV = sentry?.env || 'unknown'
			const SENTRY_DEBUG = false
			const SentryConfig = {
				dsn: SENTRY_DSN,
				debug: SENTRY_DEBUG,
				environment: SENTRY_ENV,
				tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE),
				replaysSessionSampleRate: 0,
				replaysOnErrorSampleRate: 0,
			}
			init(SentryConfig)
		}
	}

	/* Capture error */
	useEffect(() => {
		if (!skipSentry) {
			if (SENTRY_DSN && SENTRY_ENABLED) {
				useSentry()
				setTimeout(() => {
					captureMessage(message)
					captureException(stack)
				}, 500)
			} else {
				console.error(stack)
			}
		}
	}, [])

	const getStyles = () => {
		return `
            .section__img, img {
                display: block;
                margin: 0 auto;
            }
            iframe, .grecaptcha-badge, #onetrust-consent-sdk, .subscribe-modal {
                display: none;
                visibility: hidden;
            }
            .section {
                margin: 48px auto 0 auto;
                padding: 16px;
                width: 100%;
                text-align: center;
            }
            .section__h2 {
                margin-top: 16px;
                font-size: 2.375rem;
                font-weight: 400;
                letter-spacing: -0.0475rem;
            }
            .section__body {
                font-size: 1rem;
                line-height: 1.5;
                margin-top: 16px;
            }
            .section__stack {
                margin-top: 16px;
                padding: 16px;
                background-color: #f5f5f5;
                border-radius: 4px;
                overflow: auto;
                text-align: left;
            }
            .section__stack pre {
                font-size: 0.7rem;
                line-height: 1rem;
            }
            .section__btn {
                margin-top: 16px;
                padding: 8px 16px;
                background-color: #fff;
                color: #000;
                border: 1px solid #000;
                border-radius: 4px;
            }
        `
	}

	return (
		<>
			<Helmet>
				<link
					id="styles"
					rel="stylesheet"
					href={getAssetUrl('static/css/layout.css')}
				/>
			</Helmet>
			<style>{getStyles()}</style>
			<section className={`section`}>
				<div className={`section__img`}>
					<BrandLogo variant={`181x102`} />
				</div>
				<h2 className={`section__h2`}>{`Something Went Wrong`}</h2>
				<div className={`section__body`}>
					<p
						onClick={
							stack
								? () => {
										setShowStack(true)
								  }
								: undefined
						}
					>
						{`We're sorry, an unexpected error occurred. Our team has been notified and is working on it.`}
					</p>
					{resetErrorBoundary && (
						<button
							onClick={resetErrorBoundary}
							className={`section__btn notranslate`}
						>
							Try again
						</button>
					)}
				</div>
			</section>
			{stack && (
				<section
					className={`section__stack`}
					style={{
						display: showStack ? 'block' : 'none',
					}}
				>
					<pre>{stack}</pre>
				</section>
			)}
		</>
	)
}

ErrorPage.propTypes = {
	skipSentry: PropTypes.bool,
	stack: PropTypes.string,
	message: PropTypes.string,
	status: PropTypes.number,
	resetErrorBoundary: PropTypes.func,
}

export default ErrorPage
