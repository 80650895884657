/* Local Types */
import { JSONObject } from '@lib/types/JSONObject.models'

/* Fetch wrapper to prevent crash on error */
export const fetchSafely = async (
	url: string,
	extras?: JSONObject
): Promise<JSONObject | false> => {
	try {
		const response = await fetch(url, extras)
		return response ? await response.json() : false
	} catch (e) {
		return false
	}
}
