import React from 'react'

/* Components */
import {
	RolexPlaque,
	PatekPlaque,
	TudorPlaque,
} from '@components/plaques/Plaques'
import DesktopNav from '../DesktopNav/DesktopNav'
import SearchButton from '../SearchButton/SearchButton'
import ShoppingBag from '../ShoppingBag/ShoppingBag'
import SwitchLink from '@components/seo/SwitchLink'
import BrandLogo from '@components/logo/BrandLogo'
import InlineCSS from '@components/seo/InlineCSS'

/* Mocks */
import { NavigationData, TextItems } from '@lib/mock/nav.models'

/* Helpers */
import {
	routeLocations,
	routeContact,
	routeAccount,
	routeLogin,
	routeWishlist,
} from '@helpers/routeHelper'

/* Inline Styles */
import DesktopBannerStyles from '!raw-loader!postcss-loader!sass-loader!./DesktopBanner.module.scss'

/* Hooks */
import usePlaques from '../../plaques/usePlaques'
import { useIsRegistered } from '@lib/hooks/useIsRegistered'

const DesktopBanner = ({
	data,
	text,
}: {
	data: NavigationData
	text: TextItems
}): React.ReactElement => {
	/* Registered? */
	const isRegistered: boolean = useIsRegistered()

	/* Plaques */
	const plaques = usePlaques()

	const plaquesSection = () => {
		/* Possible plaques */
		const plaquesToDisplay = plaques.map((plaque) => {
			switch (plaque) {
				case 'patek':
					return <PatekPlaque variant={`desktop`} />
				case 'tudor':
					return <TudorPlaque />
				case 'rolex':
					return <RolexPlaque />
				default:
					return <div></div>
			}
		})

		return plaquesToDisplay.length === 2 ? (
			<>
				<div className={'logo-and-plaques__left'}>{plaquesToDisplay[0]}</div>
				<BrandLogo variant={`176x100`} />
				<div className={'logo-and-plaques__right'}>{plaquesToDisplay[1]}</div>
			</>
		) : null
	}

	return (
		<InlineCSS componentName={'DesktopBanner'} styles={DesktopBannerStyles}>
			<div rawclassname="desktop-banner" className={`${'container'}`}>
				<div className={'main-banner-container'}>
					<nav
						aria-label="Main"
						rawclassname="app-max-width"
						className={`${'main-banner'}`}
					>
						<div className={'main-banner__items'}>
							<SwitchLink
								href={routeLocations()}
								className={'main-banner__text'}
							>
								{text.locations}
							</SwitchLink>
							<SwitchLink href={routeContact()} className={'main-banner__text'}>
								{text.contact}
							</SwitchLink>
						</div>
						<div className={'main-banner__items'}>
							<SearchButton
								type="withText"
								text={text.search}
								variant={`primary`}
							/>
							<SwitchLink
								href={isRegistered ? routeAccount() : routeLogin()}
								className={'main-banner__text'}
								dataAttributes={{
									link: isRegistered ? 'account' : 'login',
								}}
							>
								{text.account}
							</SwitchLink>
							<SwitchLink
								href={routeWishlist()}
								className={'main-banner__text'}
							>
								{text.wishlist}
							</SwitchLink>
							<ShoppingBag variant={'primary'} isMobile={false} />
						</div>
					</nav>
				</div>
				<div rawclassname="app-max-width" className={`${'lower-banner'}`}>
					<div className={'logo-and-plaques'}>{plaquesSection()}</div>
					<DesktopNav data={data} type="full" />
				</div>
			</div>
		</InlineCSS>
	)
}

export default DesktopBanner
