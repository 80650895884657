import React from 'react'
import Image from '@next/image'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { routeBrands } from '@helpers/routeHelper'

/* Local Types */
type imageDimensionsType = {
	width: number
	height: number
}

const TudorPlaque = ({ callbackHandler }: { callbackHandler?: () => void }) => {
	/* Fixed Constants */
	const imgDimensions: imageDimensionsType = {
		width: 140,
		height: 70,
	}

	const imgAt2x: number = imgDimensions.width * 2
	const imgSrc =
		'https://govberg-sfcc.imgix.net/logos/tudor-plaque-500x250_us_jeweler.jpg?auto=format&w=' +
		imgAt2x

	/* Variable Constants */
	const clickToURL: string = routeBrands('tudor')

	return (
		<SwitchLink
			onClick={callbackHandler}
			href={clickToURL}
			data-wahfont={`13`}
			role={`link`}
		>
			<Image
				src={imgSrc}
				width={imgDimensions.width}
				height={imgDimensions.height}
				alt={`Tudor`}
			/>
		</SwitchLink>
	)
}

export default TudorPlaque
