/* Shared Types */
import { JSONObject } from '@lib/types/JSONObject.models'

/* Local Types */
import { MockCategory } from './navHelper.models'

/* Mock Hardcoded Data */
export const mockJewelry: MockCategory = {
	name: 'Jewelry',
	key: 'jewelry',
}
export const mockEditorials: MockCategory = {
	name: 'Tim Mosso',
	key: 'blog',
	vals: [
		{
			name: 'Articles',
			all: {
				name: 'See All Articles',
				key: '',
			},
			val: [
				{
					name: 'Latest Stories',
					key: 'latest-stories',
				},
				{
					name: 'Featured',
					key: 'featured',
				},
				{
					name: 'A Watch A Week',
					key: 'a-watch-a-week',
				},
				{
					name: 'Industry News',
					key: 'industry-news',
				},
				{
					name: 'Auction News',
					key: 'auction-news',
				},
				{
					name: 'Watch Reviews',
					key: 'watch-reviews',
				},
				{
					name: 'Watch 101',
					key: 'watch-101',
				},
				{
					name: 'History of Time',
					key: 'history-of-time',
				},
				{
					name: 'Collector Conversations',
					key: 'collector-conversations',
				},
				{
					name: 'Press Room',
					key: 'press-room',
				},
			],
		},
		{
			name: 'Videos',
			all: {
				name: 'See All Videos',
				href: 'https://tv.thewatchbox.com/',
			},
			val: [
				{
					name: 'Latest Shows',
					href: 'https://tv.thewatchbox.com/browse',
				},
				{
					name: 'Latest Reviews',
					href: 'https://tv.thewatchbox.com/',
				},
				{
					name: 'Watches Tonight with Tim Mosso',
					href: 'https://tv.thewatchbox.com/watches-tonight',
				},
				{
					name: 'Market Wrap with Mike Manjos',
					href: 'https://tv.thewatchbox.com/market-wrap',
				},
				{
					name: 'Collector Conversations',
					href: 'https://tv.thewatchbox.com/collector-conversations',
				},
				{
					name: 'Perpetually Patek',
					href: 'https://tv.thewatchbox.com/perpetually-patek',
				},
				{
					name: "Collector's Guide",
					href: 'https://tv.thewatchbox.com/collector-s-guide',
				},
				{
					name: 'Collector Questions',
					href: 'https://tv.thewatchbox.com/collector-questions',
				},
			],
		},
	],
}
export const mockSellAndTrade: MockCategory = {
	name: 'Sell & Trade',
	key: 'sell-and-trade',
}
export const mockExperience: JSONObject[] = [
	{
		name: 'Account',
		key: 'account/',
	},
	{
		name: 'Wishlist',
		key: 'wishlist/',
	},
	{
		name: 'Ship To: {{locale}}',
		click: 'locale',
	},
	{
		name: 'Our Locations',
		key: 'about-us/our-locations/',
	},
	{
		name: 'Chat with us',
		click: 'chat',
	},
]
export const mockPlaque: JSONObject[] = [
	{
		name: 'Patek Philippe',
		id: 'patek-philippe',
	},
	{
		name: 'Rolex Official Jeweler',
		id: 'rolex',
	},
]
