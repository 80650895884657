import React from 'react'

/* PWA Base */
import PropTypes from 'prop-types'
import { withLegacyGetProps } from '@salesforce/pwa-kit-react-sdk/ssr/universal/components/with-legacy-get-props'
import { MultiSiteProvider } from '@salesforce/retail-react-app/app/contexts'
import {
	resolveSiteFromUrl,
	resolveLocaleFromUrl,
} from '@salesforce/retail-react-app/app/utils/site-utils'
import { getConfig } from '@salesforce/pwa-kit-runtime/utils/ssr-config'
import { createUrlTemplate } from '@salesforce/retail-react-app/app/utils/url'
import { CommerceApiProvider } from '@salesforce/commerce-sdk-react'
import { withReactQuery } from '@salesforce/pwa-kit-react-sdk/ssr/universal/components/with-react-query'
import { useCorrelationId } from '@salesforce/pwa-kit-react-sdk/ssr/universal/hooks'
import { getAppOrigin } from '@salesforce/pwa-kit-react-sdk/utils/url'

/* Fixed Constants */
const isServerSide = typeof window === 'undefined'
const fixedValues = {
	siteId: 'ns-company',
	locale: 'en_US',
	currency: 'USD',
}

/* 1916 Components */
import SiteTemplate from '@components/page/SiteTemplate'

/* Local Types */
interface LocalsType {
	buildUrl?: (path: string, siteId: string, localeId: string) => string
	site?: {
		id?: string
	}
	locale?: {
		id?: string
		preferredCurrency?: string
	}
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	appConfig?: any
	originalUrl?: string
}

const AppConfig = ({
	children,
	locals,
}: {
	children: React.ReactNode
	locals: LocalsType
}): React.ReactElement => {
	const { correlationId } = useCorrelationId()
	const headers = {
		'correlation-id': correlationId,
	}
	const commerceApiConfig = locals.appConfig.commerceAPI
	const appOrigin = getAppOrigin()

	return (
		<CommerceApiProvider
			shortCode={commerceApiConfig.parameters.shortCode}
			clientId={commerceApiConfig.parameters.clientId}
			organizationId={commerceApiConfig.parameters.organizationId}
			siteId={locals.site?.id || fixedValues.siteId}
			locale={locals.locale?.id || fixedValues.locale}
			currency={locals.locale?.preferredCurrency || fixedValues.currency}
			redirectURI={`${appOrigin}/callback`}
			proxy={`${appOrigin}${commerceApiConfig.proxyPath}`}
			headers={headers}
			OCAPISessionsURL={`${appOrigin}/mobify/proxy/ocapi/s/${
				locals.site?.id || fixedValues.siteId
			}/dw/shop/v22_8/sessions`}
		>
			<MultiSiteProvider
				site={locals.site}
				locale={locals.locale}
				buildUrl={locals.buildUrl}
			>
				<SiteTemplate thirdParty={locals.appConfig.thirdParty}>
					{children}
				</SiteTemplate>
			</MultiSiteProvider>
		</CommerceApiProvider>
	)
}

/* App Methods */
AppConfig.restore = (locals: LocalsType = {}) => {
	const path =
		typeof window === 'undefined'
			? locals.originalUrl
			: `${window.location.pathname}${window.location.search}`
	const site = resolveSiteFromUrl(path)
	const locale = resolveLocaleFromUrl(path)

	const { app: appConfig } = getConfig()
	const apiConfig = {
		...appConfig.commerceAPI,
		einsteinConfig: appConfig.einsteinAPI,
	}

	apiConfig.parameters.siteId = site.id

	locals.buildUrl = createUrlTemplate(
		appConfig,
		site.alias || site.id,
		locale.id
	)
	locals.site = site
	locals.locale = locale
	locals.appConfig = appConfig
}
AppConfig.freeze = () => undefined
AppConfig.extraGetPropsArgs = (locals: LocalsType = {}) => {
	return {
		buildUrl: locals.buildUrl,
		site: locals.site,
		locale: locals.locale,
	}
}
AppConfig.propTypes = {
	children: PropTypes.node,
	locals: PropTypes.object,
}

/* Recommended settings for PWA-Kit usages */
const options = {
	queryClientConfig: {
		defaultOptions: {
			queries: {
				retry: false,
				refetchOnWindowFocus: false,
				staleTime: 10 * 1000,
				...(isServerSide ? { retryOnMount: false } : {}),
			},
			mutations: {
				retry: false,
			},
		},
	},
}

export default withLegacyGetProps(withReactQuery(AppConfig, options))
