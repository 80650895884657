/* Encode Ascii Characters */
const encodeMSWord = (value: string): string => {
	return value
		? value
				.replace(/[\u2018|\u2019|\u201A]/g, "'")
				.replace(/[\u201C|\u201D|\u201E]/g, '"')
				.replace(/\u2026/g, '...')
				.replace(/[\u2013|\u2014]/g, '-')
				.replace(/\u02C6/g, '^')
				.replace(/\u2039/g, '<')
				.replace(/\u203A/g, '>')
				.replace(/[\u02DC|\u00A0]/g, ' ')
		: value
}
const encodeAscii = (value: string): string => {
	return value
		? value
				.replace(/â€™/g, "'")
				.replace(/â€˜/g, "'")
				.replace(/â€œ/g, '"')
				.replace(/â€/g, '"')
				.replace(/â€”/g, '-')
				.replace(/â€¦/g, '...')
		: value
}
const encodeString = (value: string): string => {
	return encodeAscii(encodeMSWord(value))
}

/* Named functions */
export const removeCDATA = (str: string): string => {
	return str ? str.replace(/<!\[CDATA\[/g, '').replace(/\]\]>/g, '') : ''
}

export const removeParams = (str: string): string => {
	return str ? str.replace(/(\?|&)([^=]+)=([^&]+)/g, '') : ''
}

export const getHTMLSafeString = (value: string | null): string => {
	if (!value) {
		return ''
	} else {
		return encodeString(value)
	}
}

export const getHTMLSafeStrings = (values: string[] | null): string[] => {
	if (!values || values.length === 0) {
		return []
	}

	return values.map((val) => getHTMLSafeString(val))
}

export const getHTMLasText = (value: string | null): string => {
	return value
		? value
				.replace(/&amp;/g, '&')
				.replace(/&lt;/g, '<')
				.replace(/&gt;/g, '>')
				.replace(/&apos;/g, "'")
				.replace(/&quot;/g, '"')
				.trim()
		: ''
}

export const getNumberWithComma = (num: number): string => {
	return num > 999
		? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
		: num.toString()
}

export const getPlainText = (str?: string): string => {
	return (
		str?.replace(/&#\d+;/g, (match) => {
			return String.fromCharCode(parseInt(match.replace(/&#|;/g, '')))
		}) || ''
	)
}

export const getSummaryText = (
	str?: string,
	summaryLength?: number
): string => {
	if (str) {
		const length = summaryLength || 200
		str = str.replace(' [...]', '').replace(' [&hellip;]', '').trim()
		str = str.replace(/<p.*?>/g, '').replace(/<\/p>/g, '')
		if (str.length > length + 3) {
			str = str.substring(0, length).trim() + '...'
		} else {
			str = str + '...'
		}
	}
	return getPlainText(str)
}

export const fixPlus = (str: string | string[]): string | string[] => {
	return str && typeof str === 'string' ? str.replace(/  /g, ' +') : str
}
