import React, { useEffect } from 'react'

/* Hooks */
import { useStateChangeAttempt } from '@hooks/useStateChangeAttempt'

/* Global State */
import { setGlobalState, getGlobalState } from '@lib/state/global'

/* Local Types */
interface humanConfigType {
	scrollThreshold: number
	events: string[]
}

/* Config */
const config: humanConfigType = {
	scrollThreshold: 5,
	events: [
		'pointerdown',
		'keydown',
		'mousedown',
		'mousemove',
		'mousewheel',
		'touchcancel',
		'DOMMouseScroll',
		'MozMousePixelScroll',
	],
}

/* Detect human based on mouse & keyboard events */
export function useDetectHuman(): boolean {
	/* Local State */
	const [isHuman, setIsHuman] = useStateChangeAttempt(
		(getGlobalState()?.isHuman as boolean) || false
	) as [boolean, React.Dispatch<React.SetStateAction<boolean>>]

	/* Sync local and global state */
	const setHumanState = (state: boolean): void => {
		setIsHuman(state)
		setGlobalState({ isHuman: state })
	}

	/* Local Helpers */
	const getOffset = (): number => {
		const ret =
			typeof window.pageXOffset !== 'undefined'
				? window.pageYOffset
				: (document.compatMode || '') === 'CSS1Compat'
				  ? document.documentElement.scrollTop
				  : document.body.scrollTop
		return ret < 0 ? 0 : ret
	}
	const setEventListeners = (id: string): void => {
		const listenerKey = `${id}EventListener` as
			| 'addEventListener'
			| 'removeEventListener'
		config.events.forEach((event) => {
			if (event.toLowerCase().indexOf('scroll') > -1) {
				window[listenerKey](event, scrolled)
			} else {
				document[listenerKey](event, detected)
			}
		})
	}
	const scrolled = (): void => {
		getOffset() > config.scrollThreshold && detected()
	}
	const detected = (): void => {
		setEventListeners('remove')
		setHumanState(true)
	}

	/* Track first interaction */
	useEffect(() => {
		isHuman ? detected() : setEventListeners('add')
		return () => {
			setEventListeners('remove')
		}
	}, [isHuman])

	return isHuman
}
