import React, { useEffect, useState } from 'react'

/* Helpers */
import { getCookie, setCookie } from '@helpers/cookieHelper'
import { getSFRAEndpoint } from '@helpers/sfraHelper'
import { setEvent } from '@helpers/domHelper'

/* Local Types */
declare global {
	interface Window {
		OneTrust: {
			ToggleInfoDisplay: () => void
		}
		_satellite: {
			setVar: (key: string, value: string) => void
		}
	}
}

const OneTrustClient = (): React.ReactElement => {
	/* Track OneTrust Loaded */
	const [oneTrustLoaded, setOneTrustLoaded] = useState<boolean>(false)

	useEffect(() => {
		const interval = setInterval(() => {
			if (
				window.OneTrust &&
				document.getElementById('accept-recommended-btn-handler')
			) {
				clearInterval(interval)
				setOneTrustLoaded(true)

				document.body?.dispatchEvent(new CustomEvent('onetrust:loaded'))
			}
		}, 100)
		return () => clearInterval(interval)
	})

	/* OneTrust Event Handler */
	useEffect(() => {
		const domOpener = '.ot-sdk-show-settings'
		const domElements: string[] = [
			domOpener, // Open Settings
			'#onetrust-accept-btn-handler', // Accept All
			'#onetrust-reject-all-handler', // Reject All
			'#accept-recommended-btn-handler', // Accept Some
			'.ot-pc-refuse-all-handler', // Refuse All
			'.save-preference-btn-handler', // Check for changes
		]

		const setElementListener = (
			elem: HTMLElement,
			enable: boolean,
			event: string,
			callback: EventListener
		) => {
			setEvent(elem, event, callback, enable)
		}

		const catchOneTrustOpener = () => {
			if (window.OneTrust) {
				window.OneTrust.ToggleInfoDisplay()
			}
		}
		const setOneTrustsEvents = (enable: boolean) => {
			setElementListener(
				document.body,
				enable,
				'onetrust:toggle',
				catchOneTrustOpener
			)

			domElements.forEach((id) => {
				const elems = document.querySelectorAll(id) as NodeListOf<HTMLElement>
				if (elems && elems.length > 0) {
					elems.forEach((elem) => {
						setElementListener(
							elem,
							enable,
							'click',
							id === domOpener
								? catchOneTrustOpener
								: () => {
										checkConsentCookies(false)
								  }
						)
					})
				}
			})
		}
		const checkConsentCookies = (initialCheck?: boolean) => {
			/* Wait for OneTrust to update cookie */
			setTimeout(() => {
				const oneTrustCookie = getCookie('OptanonConsent')
				if (oneTrustCookie) {
					const oneTrustCookieParts = oneTrustCookie.split('&')
					if (oneTrustCookieParts.length > 0) {
						/* Determine SFCC and Rolex consent */
						let oneTrustAnyConsent = false
						let oneTrustRolexConsent = false
						oneTrustCookieParts.forEach((part) => {
							const keys = part.split('=')
							const key =
								decodeURIComponent(keys.length > 0 ? keys[0] : '') || ''
							if (key == 'groups') {
								const val =
									decodeURIComponent(keys.length > 1 ? keys[1] : '').replace(
										/\+/g,
										' '
									) || ''
								val?.split(',').forEach((groupval) => {
									if (groupval.indexOf(':') > -1) {
										const groupparts = groupval.split(':', 2)
										if (
											groupparts.length > 1 &&
											groupparts[1].indexOf('1') > -1
										) {
											const groupId = groupparts[0] || ''
											if (groupId) {
												if (groupId === 'Rolex') {
													oneTrustRolexConsent = true
												} else if (groupId !== 'C0001') {
													oneTrustAnyConsent = true
												}
											}
										}
									}
								})
							}
						})

						/* Set SFCC Consent Cookie */
						const consentCookieID = 'consentcookie'
						const consentCookieNow = oneTrustAnyConsent ? 'true' : 'false'
						const consentCookiePrev = getCookie(consentCookieID)
						setCookie(consentCookieID, consentCookieNow, 360)

						/* Set SFCC ActiveData & Session Tracking */
						if (initialCheck || consentCookiePrev !== consentCookieNow) {
							fetch(
								getSFRAEndpoint('ConsentTracking-SetSession', undefined, {
									consent: consentCookieNow,
								}),
								{
									method: 'GET',
									headers: {
										'Content-Type': 'application/json',
									},
								}
							)
						}

						/* Set Rolex Consent Cookie */
						const rolexCookieID = 'rlx-consent'
						const rolexCookiePrev = getCookie(rolexCookieID)
						const rolexCookieNow = oneTrustRolexConsent ? 'true' : 'false'
						setCookie(rolexCookieID, rolexCookieNow, 360)

						/* Set Adobe Analytics Consent */
						if (initialCheck || rolexCookieNow !== rolexCookiePrev) {
							if (typeof window._satellite !== 'undefined') {
								window._satellite.setVar('Analyticsconsent', rolexCookieNow)
							}
						}

						/* Alert components of current consent */
						document.body?.dispatchEvent(
							new CustomEvent('consent:' + rolexCookieNow)
						)
					}
				}
			}, 100)
		}

		/* Initial consent and any consent changes */
		if (oneTrustLoaded) {
			checkConsentCookies(true)
			setOneTrustsEvents(oneTrustLoaded)
		}

		return () => {
			setOneTrustsEvents(false)
		}
	}, [oneTrustLoaded])

	return <></>
}

export default OneTrustClient
