import { useRef, useEffect } from 'react'

/* Hooks */
import { useScrollReset, useScrollToAnchor } from '@hooks/reducedMotionHook'

/* Named functions */
export function useAutoScroll(pathname: string, hash?: string): void {
	/* Hash Ref */
	const hashRef = useRef<string | undefined>(undefined)

	/* Scroll to top or hash on path change */
	useEffect(() => {
		setTimeout(() => {
			/* Scroll to hash present? */
			const resetScroll: boolean =
				hash && hashRef.current !== hash && useScrollToAnchor(hash)
					? false
					: true

			/* Scroll to top by default */
			resetScroll && useScrollReset()

			/* Update hash ref */
			hashRef.current = hash
		}, 50)
	}, [pathname, hash])
}
