import { DataAttributes } from '@lib/types/DataAttributes.models'

/**
 *
 * Generates an object of data-* attributes that can be applied to any element
 * @returns {Object} string: string | number | boolean
 */
export const generateDataAttributes = (dataAttributes: DataAttributes) => {
	return Object.entries(dataAttributes).reduce(
		(acc, [key, value]) => {
			acc[`data-${key}`] = value
			return acc
		},
		{} as Record<string, string | number | boolean>
	)
}
