import React, { useEffect, useRef, useState } from 'react'

/* Components */
import InlineCSS from '@components/seo/InlineCSS'

/* Inline Styles */
import LoaderStyles from '!raw-loader!postcss-loader!sass-loader!./Loader.module.scss'

/* Props */
interface LoaderProps {
	/**
	 * Whether or not the loader is loading
	 */
	isLoading: boolean
	/**
	 * The text to display with the loader
	 */
	text?: string
	/**
	 * The time in milliseconds to wait before showing the loader
	 */
	timeout?: number
}

/* Constants */
const LOADER_IMAGE_URL: string =
	'https://the1916company.imgix.net/1916_BLACK_WHITE_CIRCLE_V3.gif'

const Loader = ({
	isLoading = false,
	text = 'Please Wait...',
	timeout = 600,
}: LoaderProps): React.ReactElement | null => {
	/* State */
	const [shouldShowLoader, setShouldShowLoader] = useState(false)

	/* Ref */
	const loaderRef = useRef<HTMLDivElement>(null)

	/* Effects */
	useEffect(() => {
		if (isLoading) {
			// Set 600ms timer before showing loader to prevent screen flicker
			const timeoutId = setTimeout(() => {
				setShouldShowLoader(true)
			}, timeout)

			return () => {
				clearTimeout(timeoutId)
			}
		}

		setShouldShowLoader(false)
	}, [isLoading])

	return shouldShowLoader ? (
		<InlineCSS styles={LoaderStyles} componentName={'Loader'}>
			<div ref={loaderRef} className={'container'}>
				<div className={'loader'}>
					<img
						src={LOADER_IMAGE_URL}
						alt="Loading"
						className={'loader__image'}
					/>
					<p className={'loader__text'}>{text}</p>
				</div>
			</div>
		</InlineCSS>
	) : null
}

export default Loader
