/*
Usage:
    import { getScrollBehavior } from '@lib/hooks/reducedMotionHook';
    const defaultScroll = getScrollBehavior(); // smooth or auto
    const smoothScroll = getScrollBehavior('smooth'); // smooth or auto
    const autoScroll = getScrollBehavior('auto'); // auto
    const instantScroll = getScrollBehavior('instant'); // instant or auto

    import { getReducedMotion } from '@lib/hooks/reducedMotionHook';
    const reducedMotion = getReducedMotion(); // true or false

    import { useScrollReset, useScrollIntoView } from '@lib/hooks/reducedMotionHook';
    useScrollReset();
    useScrollIntoView(document.getElementById('foo')); // return boolean
        // true (element was found)
        // false (scrolled to window top/left)
*/

/* Hooks */
import { useHeaderHeight } from '@hooks/useHeaderHeight'

/* Fixed Config */
const defaultBehavior: ScrollBehavior = 'smooth'
const autoBehavior: ScrollBehavior = 'auto'
const reducedMotionQuery: string = '(prefers-reduced-motion: reduce)'

/* Standard Resets */
const scrollReset: {
	top: number
	left: number
} = {
	top: 0,
	left: 0,
}
const scrollBlock: {
	block: ScrollLogicalPosition
	inline: ScrollLogicalPosition
} = {
	block: 'start',
	inline: 'nearest',
}

/* Local Types */
type BehaviorType = ScrollBehavior | null

/* Shared Helpers */
export const getReducedMotion = (): boolean => {
	return window.matchMedia(reducedMotionQuery)?.matches || false
}
export const getScrollBehavior = (behavior?: BehaviorType): ScrollBehavior => {
	return getReducedMotion() ? autoBehavior : behavior || defaultBehavior
}

/* Local Helpers */
const isElement = (elem: HTMLElement | null): boolean => {
	return typeof elem !== 'undefined' && elem && elem != null ? true : false
}
const executeScroll = (
	behavior?: BehaviorType,
	scrollSettings?: {
		top?: number
		left?: number
		block?: ScrollLogicalPosition
		inline?: ScrollLogicalPosition
	}
) => {
	window.scrollTo({
		...(scrollSettings || scrollReset),
		behavior: getScrollBehavior(behavior),
	})
}
const scrollExact = (top: number, behavior?: BehaviorType): void => {
	executeScroll(behavior, { top: top || 0 })
}
const scrollTo = (
	elem: HTMLElement | null,
	behavior?: BehaviorType,
	offset?: number
): boolean => {
	let ret: boolean = false
	const bodyIsFixed: boolean =
		window.getComputedStyle(document.body)?.position === 'fixed' ? true : false
	if (isElement(elem)) {
		const elemTop: number =
			(elem?.getBoundingClientRect().top || 0) -
			document.body.getBoundingClientRect().top -
			(offset || 0)
		if (bodyIsFixed) {
			document.body.style.top = `-${elemTop}px`
		} else {
			executeScroll(behavior, { top: elemTop })
		}
		ret = true
	} else {
		if (bodyIsFixed) {
			document.body.style.top = `0px`
		} else {
			executeScroll(behavior)
		}
	}
	return ret
}
const scrollToElement = (
	elem: HTMLElement | null,
	behavior?: BehaviorType
): boolean => {
	let ret: boolean = false
	if (isElement(elem)) {
		elem?.scrollIntoView({
			...scrollBlock,
			behavior: getScrollBehavior(behavior),
		})
		ret = true
	} else {
		executeScroll(behavior)
	}
	return ret
}
const scrollToAnchor = (anchor: string, updateHash?: boolean): boolean => {
	anchor = anchor?.replace('#', '') || ''
	if (anchor) {
		/* Catch invalid selectors */
		let elem: HTMLElement | null = null
		try {
			elem = document.getElementById(anchor)
		} catch (e) {
			elem = null
		}
		if (elem) {
			/* Scroll to anchor with header offset */
			scrollTo(elem, null, useHeaderHeight()(16, true))

			/* Update hash? */
			if (updateHash) {
				window.location.hash = anchor
			}
			return true
		}
	}
	return false
}

/* Named functions */
export function useScrollIntoView(
	elem: HTMLElement | null,
	behavior?: BehaviorType
): boolean {
	return scrollToElement(elem, behavior)
}
export function useScrollReset(behavior?: BehaviorType): boolean {
	return scrollToElement(null, behavior)
}
export function useScrollDownTo(
	elem: HTMLElement | null,
	behavior?: BehaviorType,
	offset?: number
): boolean {
	return scrollTo(elem, behavior, offset)
}
export function useScrollTopTo(top: number, behavior?: BehaviorType): void {
	scrollExact(top, behavior)
}
export function useScrollToAnchor(
	anchor: string,
	updateHash?: boolean
): boolean {
	return scrollToAnchor(anchor, updateHash)
}
