import React, { useEffect, useRef, useState } from 'react'
import { useRouter } from '@next/navigation'
import parse from 'html-react-parser'
import Image from '@next/image'

/* Components */
import SearchButton from '../SearchButton/SearchButton'
import SwitchLink from '@components/seo/SwitchLink'
import BrandLogo from '@components/logo/BrandLogo'
import InlineCSS from '@components/seo/InlineCSS'

/* Helpers */
import { getNavLink, getItemLink } from '@helpers/navHelper'
import { randomUUID } from '@helpers/navHelper/navHelper.hooks'

/* Hooks */
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'

/* Styles */
import DesktopNavStyles from '!raw-loader!postcss-loader!sass-loader!./DesktopNav.module.scss'

/* Shared Types */
import {
	CatalogItem,
	CatalogItemSection,
	NavigationData,
} from '@lib/mock/nav.models'

/* Local Types */
interface DesktopNavDropdownRef {
	[key: string]: HTMLDivElement | null
}
interface DesktopNavDropdownCurrentLeftOffset {
	[key: string]: number
}

/* Constants */
const maxSectionItems: number = 16

const DesktopNav = ({
	data,
	type = 'full',
}: {
	data: NavigationData
	type?: 'full' | 'mini'
}) => {
	const router = useRouter()
	const { catalog } = data

	/* Unfocus active element */
	const unfocusThis = (): void => {
		const elem = document.activeElement
		if (elem) {
			(document.activeElement as HTMLElement)?.blur()
		}
	}

	/* Hide nav on link click */
	const [hideNavs, setHideNavs] = useState(false)
	useEffect(() => {
		if (hideNavs) {
			setTimeout(() => {
				setHideNavs(false)
			}, 50)
		}
	}, [hideNavs])

	/* Swap dropdowns availability? */
	const [canShowDropdowns, setCanShowDropdowns] = useStateChangeAttempt(true)
	const swapCanShowDropdowns = (val: boolean): void => {
		setCanShowDropdowns(val)
	}

	/* Throttle onResize to re-align dropdowns */
	const resizeThrottle: number = 300
	const resizeInProgress = useRef<boolean>(false)
	const ref = useRef<HTMLDivElement>(null)
	const dropdownRefs = useRef<DesktopNavDropdownRef>({})
	const itemRefs = useRef<DesktopNavDropdownRef>({})
	const [currentItemRefLeftOffsets, setCurrentItemRefLeftOffsets] =
		useState<DesktopNavDropdownCurrentLeftOffset>({})

	useEffect(() => {
		const addResize = (add: boolean, remove: boolean): void => {
			const onResize = () => {
				if (resizeInProgress.current !== true) {
					resizeInProgress.current = true
					setTimeout(() => {
						setCurrentItemRefLeftOffsets(
							Object.keys(itemRefs.current).reduce((acc, key) => {
								acc[key] = itemRefs.current[key]?.offsetLeft || 0
								return acc
							}, {} as DesktopNavDropdownCurrentLeftOffset)
						)
						resizeInProgress.current = false
					}, resizeThrottle)
				}
			}
			if (remove) {
				window.removeEventListener('resize', onResize)
				ref.current?.removeEventListener('mouseenter', onResize)
				Object.keys(dropdownRefs.current).forEach((key) => {
					dropdownRefs.current[key]?.removeEventListener('mouseenter', onResize)
				})
			}
			if (add) {
				window.addEventListener('resize', onResize)
				ref.current?.addEventListener('mouseenter', onResize)
				Object.keys(dropdownRefs.current).forEach((key) => {
					dropdownRefs.current[key]?.addEventListener('mouseenter', onResize)
				})
				onResize()
			}
		}

		addResize(true, true)
		return addResize(false, true)
	}, [])

	/* Capture enter key on dropdown */
	const handleEnter = (
		e: React.KeyboardEvent<HTMLDivElement>,
		href: string
	) => {
		if (e && e.key === 'Enter') {
			e.preventDefault()
			setHideNavs(true)
			router.push(href)
		}
	}

	/* Local Components */
	const itemSections = (item: CatalogItem, sections: CatalogItemSection[]) => {
		let variation: string = sections.length == 1 ? 'narrow' : ''
		if (item.key === 'jewelry') {
			variation = 'jewelry'
		} else if (item.key && item.key.indexOf('rolex') > -1) {
			variation = 'rolex'
		} else if (
			sections &&
			sections.length > 1 &&
			sections[0].val &&
			sections[0].val.length < 7
		) {
			variation = 'variable'
		}

		const variableMaxSectionItems =
			item.key === 'rolex-certified-pre-owned' ? 18 : maxSectionItems

		return (
			<div rawclassname="app-max-width" className={`${'sections-container'}`}>
				{sections && (
					<div
						className={`${'sections'} ${
							variation ? 'sections--' + variation : ''
						}`}
					>
						{sections.map((section, index) => {
							let allHref: string = ''
							let allTarget: string = ''
							if (section.all) {
								allHref = section.all.href
									? section.all.href
									: section.all.key
									  ? '/' + (item.dir || item.key) + '/' + section.all.key + '/'
									  : '/'
								allTarget = (allHref.indexOf('://') == -1 ? '' : '_blank') || ''
							}
							return (
								<div
									key={section.name}
									className={`${'section'}`}
									style={{
										gridColumn:
											variation === 'variable' && index == 1
												? 'span 2'
												: 'auto',
									}}
								>
									<span className={`${'section__header'}`}>{section.name}</span>
									{section.val && (
										<ul
											className={`${'section__items'} ${
												'section__items--col' +
												Math.ceil(section.val.length / 6)
											}`}
										>
											{section.val
												.slice(0, variableMaxSectionItems)
												.map((val) => {
													const { target, href } = getNavLink(item, val)
													const props = {
														key: val.uuid || randomUUID(),
														href: href,
														target: target,
														className: `${'section__item-text'} ${
															val.highlight
																? 'section__item-text--highlight'
																: ''
														}`.trim(),
														onClick: () => {
															setHideNavs(true)
														},
													}

													if (val.key === 'all-brands') {
														props.className += ' ' + 'section__item-text--bold'
													}

													return target ? (
														<a {...props}>
															<span>{val.name}</span>
														</a>
													) : (
														<SwitchLink {...props}>
															<span>{val.name}</span>
														</SwitchLink>
													)
												})}
										</ul>
									)}
									{allHref &&
										(allTarget ? (
											<a
												href={allHref}
												target={allTarget || undefined}
												className={`${'section__footer'}`}
												onClick={() => {
													setHideNavs(true)
												}}
											>
												{section.all?.name}
											</a>
										) : (
											<SwitchLink
												href={allHref}
												className={`${'section__footer'}`}
												onClick={() => {
													setHideNavs(true)
												}}
											>
												{section.all?.name}
											</SwitchLink>
										))}
								</div>
							)
						})}
						<div className={`${'asset'}`}>
							{item.asset ? parseAssetAndConvertImages(item.asset) : null}
						</div>
					</div>
				)}
			</div>
		)
	}

	/* Convert incoming asset images */
	const parseAssetAndConvertImages = (asset: string): React.ReactElement => {
		const ret = parse(asset) as React.ReactElement
		const hasNoPictures: boolean =
			asset.indexOf('<picture') === -1 ? true : false
		if (hasNoPictures && ret && ret.props && ret.props.children) {
			let anyImages: boolean = false
			const newChildren = React.Children.map(ret.props.children, (child) => {
				const isImg: boolean = child.type === 'img'
				if (isImg) {
					anyImages = true
					return (
						<Image
							sizes={true}
							src={child.props.src}
							alt={child.props.alt || ''}
							width={488}
							height={310}
							className={`${'image'}`}
						/>
					)
				} else {
					return child
				}
			})
			if (anyImages) {
				return React.cloneElement(ret, { children: newChildren })
			}
		}
		return ret
	}

	/* Shared event for showing dropdowns */
	const canShowEvent = () => {
		swapCanShowDropdowns(true)
	}

	const navItems = (
		<div className={`${'desktop-nav-items__list'}`}>
			{catalog &&
				catalog.map((item, index) => {
					const href = getItemLink(item)
					const itemKey: string = item.key || index.toString()
					return (
						<div
							key={itemKey}
							ref={(el) => (dropdownRefs.current[itemKey] = el)}
						>
							{item.vals ? (
								<div
									ref={(el) => (itemRefs.current[itemKey] = el)}
									tabIndex={0}
									className={`top-level ${'dropdown'}`}
									onKeyDown={(e) => {
										handleEnter(e, href)
									}}
								>
									<SwitchLink
										className={`${'dropdown__item'}`}
										href={href}
										onClick={() => {
											unfocusThis()
											swapCanShowDropdowns(false)
										}}
										onMouseEnter={canShowEvent}
										onTouchStart={canShowEvent}
										onMouseLeave={canShowEvent}
										onTouchEnd={canShowEvent}
									>
										{item.name}
									</SwitchLink>
									<div
										className={`${'dropdown__content'} ${
											hideNavs || !canShowDropdowns
												? 'dropdown__content--hide'
												: ''
										}`}
										style={{
											marginLeft:
												-currentItemRefLeftOffsets[itemKey] ||
												-(itemRefs.current[itemKey]?.offsetLeft || 0),
										}}
									>
										{itemSections(item, item.vals as CatalogItemSection[])}
									</div>
								</div>
							) : (
								<SwitchLink
									className={'link-item'}
									href={href}
									onClick={() => {
										unfocusThis()
									}}
								>
									{item.name}
								</SwitchLink>
							)}
						</div>
					)
				})}
		</div>
	)
	return (
		<InlineCSS componentName={'DesktopNav'} styles={DesktopNavStyles}>
			<div
				ref={ref}
				className={`${'desktop-nav-items'} ${'desktop-nav-items--' + type} ${
					hideNavs ? 'desktop-nav-items--disable' : ''
				}`}
			>
				{type === 'mini' ? (
					<div className={`${'desktop-nav-items__left'}`}>
						<BrandLogo variant={`48x49`} />
					</div>
				) : null}
				{navItems}
				{type === 'mini' ? (
					<div className={`${'desktop-nav-items__right'}`}>
						<SearchButton type="icon" />
					</div>
				) : null}
			</div>
		</InlineCSS>
	)
}

export default DesktopNav
