import { useEffect, useState } from 'react'

/* Helpers */
import { fetchSafely } from '@helpers/fetchHelper'
import { getSFRAEndpoint } from '@helpers/sfraHelper'

/* Hooks */
import { sendGTAGObject } from '@helpers/hooksHelper'
import { useDetectHuman } from '@hooks/useDetectHuman'

/* Constants */
const endpoint: string = 'Account-LazyLoad'

/* Local Types */
export type AccountVarsType = {
	vars: {
		AccountSFID: string
	}
} | null
export type AccountDataType = AccountVarsType | false
export type UseAccountLazyLoadReturnType = [
	AccountDataType,
	React.Dispatch<React.SetStateAction<AccountDataType>>,
	() => void,
]

export const useAccountLazyLoad = (): UseAccountLazyLoadReturnType => {
	const [accountLazyLoad, setaccountLazyLoad] = useState<AccountDataType>(null)
	const isHuman = useDetectHuman()

	const fetchaccountLazyLoad = async () => {
		const data = (await fetchSafely(
			getSFRAEndpoint(endpoint)
		)) as AccountVarsType
		if (data) {
			setaccountLazyLoad(data)
			if (data.vars?.AccountSFID) {
				sendGTAGObject('set', { user_id: data.vars.AccountSFID })
			}
		}
	}

	useEffect(() => {
		isHuman && fetchaccountLazyLoad()
	}, [isHuman])

	const refreshAccountLazyLoad = () => {
		fetchaccountLazyLoad()
	}

	return [accountLazyLoad, setaccountLazyLoad, refreshAccountLazyLoad]
}
