import { configType } from './faviconHelper.models'

/* Local Config */
const config: configType = {
	icons: {
		icon: [
			{ rel: 'icon', type: 'image/x-icon', href: 'favicon.ico' },
			{ rel: 'alternate icon', href: 'favicon.png' },
			{ rel: 'icon', href: 'favicon-16x16.png', sizes: '16x16' },
			{ rel: 'icon', href: 'favicon-32x32.png', sizes: '32x32' },
			{ rel: 'icon', href: 'favicon-96x96.png', sizes: '96x96' },
			{ rel: 'icon', href: 'favicon-128.png', sizes: '128x128' },
			{ rel: 'icon', href: 'favicon-192x192.png', sizes: '192x192' },
		],
		android: [{ rel: 'manifest', href: 'manifest.json' }],
		apple: [
			{ rel: 'apple-touch-icon', href: 'apple-touch-icon.png' },
			{ rel: 'apple-touch-icon-precomposed', href: 'favicon-128.png' },
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-57x57.png',
				sizes: '57x57',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-72x72.png',
				sizes: '72x72',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-76x76.png',
				sizes: '76x76',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-114x114.png',
				sizes: '114x114',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-120x120.png',
				sizes: '120x120',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-144x144.png',
				sizes: '144x144',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-152x152.png',
				sizes: '152x152',
			},
			{
				rel: 'apple-touch-icon',
				href: 'apple-touch-icon-180x180.png',
				sizes: '180x180',
			},
		],
	},
	other: {
		'mobile-web-app-capable': 'yes',
		'apple-mobile-web-app-capable': 'yes',
		'apple-mobile-web-app-status-bar-style': 'black-translucent',
		'apple-mobile-web-app-title': 'The 1916 Company',
		'application-name': 'The 1916 Company',
		'msapplication-TileColor': '#ffc40d',
		'theme-color': '#ffffff',
	},
}

/* Helper */
const faviconHelper = {
	metas: (): configType => {
		return config
	},
}

/* Named functions */
export function getIconMetas(): configType {
	return faviconHelper.metas()
}
