import React, { useContext } from 'react'

/* Context */
import { SiteHeaderContext } from '../SiteHeaderProvider'

/* Components */
import { Icon } from '@components/Icon/Icon'
import { Btn } from '@components/Btn/Btn'
import InlineCSS from '@components/seo/InlineCSS'

/* Inline Styles */
import SearchButtonStyles from '!raw-loader!postcss-loader!sass-loader!./SearchButton.module.scss'

const SearchButton = ({
	type = 'withText',
	text,
	variant,
}: {
	type?: 'icon' | 'withText'
	text?: string
	variant?: 'secondary' | 'primary'
}): React.ReactElement => {
	const { showSearch, setShowSearch } = useContext(SiteHeaderContext)

	return (
		<InlineCSS componentName={'SearchButton'} styles={SearchButtonStyles}>
			<Btn
				rawclassname={`search-button ${
					type === 'withText' ? 'search-button--with-text' : ''
				}`}
				variant={`icon`}
				handleClick={() => setShowSearch(!showSearch)}
				ariaLabel={`Search`}
			>
				<Icon
					name={'navSearch'}
					size={'24'}
					title={text}
					variant={variant || `secondary`}
				/>
				{type == 'withText' ? text : ''}
			</Btn>
		</InlineCSS>
	)
}

export default SearchButton
