/* Defaults */
const defaultValues: {
	language: string
	country: string
} = {
	language: 'en',
	country: 'US',
}

/* Language and Country with default fallbacks */
export const safeParseLanguageAndCountry = (
	val: string,
	def: string
): [string, string] => {
	let language: string = ''
	let country: string = ''
	const possibleValue: string = (val || def).replace('-', '_')
	if (possibleValue.indexOf('_') == -1) {
		[language, country] = [defaultValues.language, defaultValues.country]
	} else {
		try {
			[language, country] = possibleValue.split('_')
		} catch (e) {}
	}
	if (!country) {
		country = defaultValues.country
	}
	if (!language) {
		language = defaultValues.language
	}
	return [language.toLowerCase(), country.toUpperCase()]
}
