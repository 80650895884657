import React from 'react'

import PlayIconStyles from './PlayIcon.module.scss'
import SwitchLink from '@components/seo/SwitchLink'

const PlayButton = ({ linkHref }: { linkHref: string }) => {
	const logoType = () => {
		return (
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width={106}
				height={106}
				viewBox={`0 0 106 106`}
				fill="none"
			>
				<g opacity="1">
					<circle
						cx="52.6904"
						cy="52.6904"
						r="52.6904"
						fill="#DFDDD2"
						fillOpacity="0.9"
					/>
					<path
						d="M86.9808 52.6905L30.5267 85.2842L30.5267 20.0967L86.9808 52.6905Z"
						fill="white"
					/>
				</g>
			</svg>
		)
	}
	return (
		<SwitchLink
			className={`${PlayIconStyles['play-icon']}`}
			href={linkHref}
			target={`_blank`}
			aria-label={`See More Videos`}
		>
			{logoType()}
		</SwitchLink>
	)
}

export default PlayButton
