import React, { useState } from 'react'

/* Components */
import SwitchLink from '@components/seo/SwitchLink'

/* Helpers */
import { getImgixURLAtWidth } from '@helpers/imgixHelper'

/* Styles */
import NewsBannerStyles from './NewsBanner.module.scss'

/* Local Types */
interface NewsBannerItem {
	id: number
	newsLogoHeight: number
	newsLogoWidth: number
	newsLogoSrc: string
	newsLogoAlt: string
	linkHref: string
}

const NewsBanner = ({ items }: { items: NewsBannerItem[] }) => {
	const [currentlyHoveredBulletIndex, setCurrentlyHoveredBulletIndex] =
		useState(-1)

	return (
		<div className={`${NewsBannerStyles['container']}`}>
			<h2 className={`${NewsBannerStyles['header']}`}>In the News</h2>
			<div className={`${NewsBannerStyles['items']}`}>
				{items.map((item, index) => (
					<SwitchLink
						key={item.id}
						href={item.linkHref}
						className={`${NewsBannerStyles['item']}`}
						onMouseOver={() => setCurrentlyHoveredBulletIndex(index)}
						onMouseOut={() => setCurrentlyHoveredBulletIndex(-1)}
						target="_blank"
					>
						<img
							src={getImgixURLAtWidth(item.newsLogoSrc, item.newsLogoWidth)}
							alt={item.newsLogoAlt}
							height={item.newsLogoHeight}
							width={item.newsLogoWidth}
							loading={'lazy'}
							className={`${NewsBannerStyles['picture']}`}
						/>
						<div
							aria-hidden={true}
							className={`${NewsBannerStyles['selection']} ${
								currentlyHoveredBulletIndex === index
									? NewsBannerStyles['selection--show']
									: ''
							}`}
						/>
					</SwitchLink>
				))}
			</div>
		</div>
	)
}

export default NewsBanner
