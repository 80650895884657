import React from 'react'

/* Template Id */
const template: string = 'home'

/* Helpers */
import { getCustomObjectRecord } from '@helpers/ocapiCustomAPIHelper'
import { withPageCSS } from '@helpers/helmetHelper'
import { routeHome } from '@helpers/routeHelper'

/* 1916 Components */
import Page from '@components/page/Page'
import HomePage from '@components/home/HomePage'
import PageLoading from '@components/page/PageLoading'

/* Fatal Components */
import SomethingWentWrong from '@components/page/SomethingWentWrong'

/* CMS Types */
import { StrapiHomepage } from '@govberg/flageollet-types'

/* Local Types */
interface HomeProps {
	isReady?: boolean
	strapiHomepage?: StrapiHomepage
}
interface HomeStrapiConverted {
	data?: StrapiHomepage
}

/* Fixed Config */
const sfccRecord = {
	customObjectName: '1916Strapi',
	customObjectKey: 'homepage',
}

const Home = ({ isReady, strapiHomepage }: HomeProps): React.ReactElement => {
	/* Fatal error when no data available */
	if (isReady && !strapiHomepage) {
		return withPageCSS('something-went-wrong', <SomethingWentWrong />)
	}

	return withPageCSS(
		template,
		!isReady ? (
			<PageLoading />
		) : (
			<Page canonical={routeHome()}>
				<div>
					<HomePage homepage={strapiHomepage} />
				</div>
			</Page>
		)
	)
}

/* Server Side Props */
Home.getProps = async () => {
	const { customObjectName, customObjectKey } = sfccRecord
	const data =
		(await getCustomObjectRecord(customObjectName, customObjectKey)) ?? false
	const strapiHomepageContent = data
		? (data?.c_content as string) ?? null
		: null

	const failedResponse = {
		isReady: true,
		strapiHomepage: null,
	}

	if (!strapiHomepageContent) {
		return failedResponse
	}

	try {
		const strapiHomepageConverted = JSON.parse(
			strapiHomepageContent
		) as HomeStrapiConverted
		return {
			isReady: true,
			strapiHomepage: strapiHomepageConverted.data,
		}
	} catch (e) {
		console.error('Homepage Fatal: ', e)
		return failedResponse
	}
}

/* Template Name */
Home.getTemplateName = () => template

export default Home
