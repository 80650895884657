import React from 'react'

interface GTMNoScriptProps {
	gtmId: string
}

export default function GTMNoScript(
	props: GTMNoScriptProps
): React.ReactElement {
	const { gtmId } = props

	// Pass as string to <noscript> to avoid rendering as iframe directly
	const noscript = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`

	return (
		<noscript
			id="gtm-noscript"
			dangerouslySetInnerHTML={{
				__html: noscript,
			}}
		/>
	)
}
