import React, { useEffect, useCallback, Suspense, lazy } from 'react'

/* Components */
import InlineCSS from '@components/seo/InlineCSS'

/* Lazy Components */
const SiteFooter = lazy(() =>
	import('@components/footer/Footer').catch(() => {
		return { default: () => <></> }
	})
)

/* Helpers */
import {
	getCustomObjectRecord,
	getContentAsset,
} from '@helpers/ocapiCustomAPIHelper'

/* Hooks */
import { useStateChangeAttempt } from '@lib/hooks/useStateChangeAttempt'
import { useFirstRenderOnScroll } from '@lib/hooks/useFirstRenderOnScroll'

/* Shared Types */
import { DataAccordionsType } from '@components/footer/FooterAccordion.models'

/* Inline Styles */
import FooterStyles from '!raw-loader!postcss-loader!sass-loader!./Footer.scss'

const Footer = (): React.ReactElement => {
	/* Render on first scroll down >5px */
	const render = useFirstRenderOnScroll(5)

	/* Fetch Accordions & Social Links */
	const [accordions, setAccordions] = useStateChangeAttempt(null)
	const [socialLinks, setSocialLinks] = useStateChangeAttempt(null)

	/* TODO: move hookAccordionData to accordions data inside /api/object/index.ts */

	/* Grab data once per SPA instance */
	const getAccordions = useCallback(async () => {
		if (render) {
			const data = await getCustomObjectRecord('1916Strapi', 'accordions')
			if (data && data.c_content) {
				setAccordions(data)
			}
		}
	}, [render])

	const getSocialLinks = useCallback(async () => {
		if (render) {
			const data = await getContentAsset('ns-footer-column-social')
			const dataBody =
				data && data.online && data.body ? data.body : '<div></div>'
			setSocialLinks(dataBody)
		}
	}, [render])

	/* Fetch Data on Render */
	useEffect(() => {
		if (render && getAccordions && !accordions) {
			getAccordions()
		}
		if (render && getSocialLinks && !socialLinks) {
			getSocialLinks()
		}
	}, [render, getAccordions, getSocialLinks])

	return (
		<InlineCSS componentName={`Footer`} styles={FooterStyles}>
			<footer id={`footer`} className={`main`} rawclassname={`footer`}>
				{render && accordions && socialLinks  ? (
					<Suspense fallback={<></>}>
						<SiteFooter
							accordions={accordions as DataAccordionsType}
							socialLinks={socialLinks as string}
						/>
					</Suspense>
				) : (
					<></>
				)}
			</footer>
		</InlineCSS>
	)
}

export default Footer
